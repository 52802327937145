import {
  LOGOUT,
  CREATE_OR_GET_EVENT,
  SET_EVENT_ANSWERS,
  SUBMIT_EVENT_SURVEY,
  RESET_REDIRECT
} from "../actions/Types";
import Immutable from "seamless-immutable";

import SurveyType from "../../shared/constants/survey";
import { setOrGetUserAnalytics } from "../../utils/Mixpanel";

// initial state

const initialState = Immutable({
  survey: null,
  user: null,
  surveyTpe: SurveyType.EVENT,
  credentials: {
    user: null,
    name: null,
    firstName: null,
    lastName: null,
    email: null,
    password: null
  },
  isSignedIn: false,
  answers: {
    ohAParty: null,
    eventDate: null,
    eventType: null,
    weddingDetails: null,
    name: null,
    email: null,
    jewelryType: null,
    photoUpload: null,
    budget: null,
    layerItOn: null,
    anythingElse: null,
    addEventBox: null,
    stylistDetails: null
  },
  tags: {},
  redirect: null
});

// reducers
const setEventAnswers = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    answers: {
      ...state.answers,
      [action.question.name]: action.answer
    }
  });
};

const submitEventSurvey = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    redirect: action.redirect
  });
};

const resetRedirect = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    redirect: null
  });
};

const createOrGetUser = (state, action) => {
  const { name, user, survey, email } = action;
  setOrGetUserAnalytics({ name, user, survey, email });

  return Immutable.merge(state, {
    ...state,
    user: user ? user : state.user,
    survey: survey ? survey : state.survey,
    credentials: {
      ...state.credentials,
      user: user ? user : state.credentials.user,
      name: email ? email.split("@")[0] : state.credentials.name,
      email: email ? email : state.credentials.email,
      firstName: name ? name : state.credentials.name
    },
    answers: {
      ...state.answers,
      name: name ? name : state.answers.name,
      email: email ? email : state.answers.email
    },
    isSignedIn: true,
    isLoading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OR_GET_EVENT:
      return createOrGetUser(state, action);
    case SET_EVENT_ANSWERS: {
      return setEventAnswers(state, action);
    }
    case SUBMIT_EVENT_SURVEY:
      return submitEventSurvey(state, action);
    case RESET_REDIRECT:
      return resetRedirect(state, action);
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
