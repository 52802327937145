import { combineReducers } from "redux";
import user from "./User";
import event from "./Event";
import gift from "./Gift";
import photos from "./Photos";

const rootReducer = combineReducers({
  event,
  user,
  gift,
  photos,
});

export default rootReducer;
