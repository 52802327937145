import React, { useState } from "react";
import styled from "styled-components";
import { CloseButton } from "../molecules/Footer";

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 999;
  background-color: white;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 2px 10px #3d4543;
  padding: 35px 0 35px 0;
  min-width: 350px;
  margin: auto;
  width: 30%;
  top: 130px;
  left: 0;
  right: 0;
`;

const ModalInput = styled.input`
  display: flex;
  font-size: 14px;
  margin: 0 2% 2% 2%;
  padding 8px 0px 8px 15px;
  border: 1px solid #dedede;
  color: #3d4543;
  letter-spacing: 1px;
  width: 90%;
`;

const ModalHeader = styled.h1`
  align-self: flex-start;
  font-weight: normal;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0 0 2% 4%;
  text-align: left;
  width: auto;
`;

const ModalSubHeader = styled.h2`
  align-self: flex-start;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  margin: 0 2% 4% 4%;
  color: #3d4543;
  width: auto;
`;

const SignUpFooter = styled.h2`
  display: flex;
  height: 20px;
  cursor: default;
  color: rgb(61, 69, 67, 0.6);
  font-size: 14px;
  line-height: 17px;
  margin: 4% 0 0 0;
  width: auto;

  &:hover {
    color: rgb(61, 69, 67, 0.8);
  }

  &:active {
    color: rgb(61, 69, 67, 1);
  }
`;

const SubmitButton = styled.div`
  cursor: default;
  display: flex;
  border-radius: 3px;
  background-color: #336472; // skyBlue
  color: white;
  padding: 15px 0 15px;
  margin: 1% 0 1% 0;
  justify-content: center;
  width: 94%;

  &:hover {
    background-color: rgb(74, 144, 157, 0.8);
  }

  &:active {
    background-color: rgb(74, 144, 157, 1);
  }
`;

const ValidationMessage = styled.div`
  display: flex;
  color: red;
  margin: 10px 0 10px;
  justify-content: center;
  width: auto;
`;

export function ErrorModal(props) {
  const { showErrorModal, errorMessage, errorAction } = props;
  return showErrorModal ? (
    <ModalContainer className="modal">
      <ModalHeader>Error has occured</ModalHeader>
      <ModalSubHeader>{errorMessage}</ModalSubHeader>
      <CloseButton closeModal={errorAction} />
    </ModalContainer>
  ) : null;
}

export function Modal(props) {
  const {
    show,
    signIn,
    signUp,
    credentials,
    isLogin,
    toggleLogin,
    toggleShowModal
  } = props;
  const [formDetails, setFormDetails] = useState({
    email: credentials.email ? credentials.email : "",
    password: credentials.password ? credentials.password : "",
    firstName: credentials.firstName ? credentials.firstName : "",
    lastName: credentials.lastName ? credentials.lastName : ""
  });
  const [isValid] = useState(true);
  const handleChange = e => {
    const newFormDetails = formDetails;
    newFormDetails[e.target.name] = e.target.value;
    setFormDetails({ ...newFormDetails });
  };
  const handleSignUp = () => {
    signUp(formDetails);
    toggleShowModal();
  };
  const handleSignIn = () => {
    signIn(formDetails);
    toggleShowModal();
  };

  return show ? (
    <ModalContainer className="modal">
      <ModalHeader>
        {isLogin ? "Welcome back!" : "Welcome to Layerist"}
      </ModalHeader>
      <ModalSubHeader>Sign in to your Layerist account.</ModalSubHeader>
      {isLogin ? null : (
        <ModalInput
          name="firstName"
          value={formDetails.firstName}
          placeholder="FIRST NAME"
          onChange={handleChange}
        />
      )}
      {isLogin ? null : (
        <ModalInput
          name="lastName"
          value={formDetails.lastName}
          placeholder="LAST NAME"
          onChange={handleChange}
        />
      )}
      <ModalInput
        name="email"
        value={formDetails.email}
        placeholder="EMAIL"
        onChange={handleChange}
      />
      {isValid || formDetails.email === "" ? null : (
        <ValidationMessage>Invalid answer</ValidationMessage>
      )}
      <ModalInput
        name="password"
        value={formDetails.password}
        placeholder="PASSWORD"
        type="password"
        onChange={handleChange}
      />
      {/* <ModalSubHeader>Forgot your password?</ModalSubHeader> */}
      <SubmitButton onClick={isLogin ? handleSignIn : handleSignUp}>
        {/* <BarLoader
          // css={override}
          size={15}
          color={"#123abc"}
          loading={true}
        /> */}
        {isLogin ? "Sign In" : "Sign Up"}
      </SubmitButton>
      <SignUpFooter onClick={toggleLogin}>
        {isLogin
          ? `Don't have an account? Sign Up`
          : `Already a member? Login.`}
      </SignUpFooter>
    </ModalContainer>
  ) : null;
}
