import {
  CREATE_OR_GET,
  GET_USER_SURVEY,
  HIDE_ERROR,
  HIDE_LOADING,
  LOGOUT,
  RESET_REDIRECT,
  SET_USER_ANSWERS,
  SHOW_ERROR,
  SHOW_LOADING_USER,
  SIGN_IN,
  SUBMIT_USER_SURVEY
} from "./Types";
import api from "../../api";

// constants
import { SurveyType } from "../../shared/constants/survey";

// transforms
import reduxToApiUser from "../../transforms/reduxUserToApiUser";

export function resetError() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: HIDE_ERROR });
    } catch (e) {
      dispatch({ type: SHOW_ERROR, error: e });
      console.error(e);
    } finally {
      dispatch({ type: HIDE_ERROR });
    }
  };
}

export function updateAnswer(question, answer) {
  return async function (dispatch, getState) {
    try {
      let { answers } = getState().user;
      dispatch({ type: SET_USER_ANSWERS, answers, question, answer });
    } catch (e) {
      dispatch({ type: SHOW_ERROR, error: e });
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function createOrGetUser(type) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING_USER });
      let { email, name } = getState().user.answers;
      const result = await api.user.createOrGetUser(email, name, type);
      if (result.success) {
        const data = result.data;

        dispatch({
          type: CREATE_OR_GET,
          user: data.user,
          survey: data.survey
        });
      } else {
        dispatch({ type: SHOW_ERROR, error: result.data.error });
      }
    } catch (e) {
      dispatch({ type: SHOW_ERROR, error: e });
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function signUp(formDetails) {
  return async function (dispatch, getState) {
    try {
      const email = formDetails.email;
      const password = formDetails.password;
      const firstName = formDetails.firstName;
      const lastName = formDetails.lastName;
      dispatch({ type: SHOW_LOADING_USER });
      const result = await api.user.signUp({
        firstName,
        lastName,
        email,
        password
      });
      if (result.success) {
        const data = result.data;
        dispatch({
          type: SIGN_IN,
          user: data.id,
          name: data.name,
          email: data.email
        });
      } else {
        const data = result.data;
        if ((result.error = "Shopify customer account already exists")) {
          const name = formDetails.firstName;
          const type = SurveyType["USER"];
          const createResult = await api.user.createOrGetUser(
            email,
            name,
            type
          );
          if (createResult.success) {
            dispatch({
              type: SIGN_IN,
              user: data.id,
              name: data.name,
              email: data.email
            });
          }
        } else {
          dispatch({
            type: SIGN_IN,
            user: data.id,
            name: data.name,
            email: data.email
          });
        }
        dispatch({ type: SHOW_ERROR });
      }
    } catch (e) {
      dispatch({ type: SHOW_ERROR, error: e });
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function signIn(formDetails) {
  return async function (dispatch, getState) {
    try {
      const email = formDetails.email;
      const password = formDetails.password;
      const name = formDetails.firstName;
      dispatch({ type: SHOW_LOADING_USER });
      const result = await api.user.login(email, password);
      if (result.success) {
        const data = result.data;
        const type = SurveyType["USER"];
        const createResult = await api.user.createOrGetUser(email, name, type);
        if (createResult.success) {
          const createData = createResult.data;
          dispatch({
            type: SIGN_IN,
            user: data.id,
            survey: createData.survey,
            name: data.name,
            email: data.email
          });
        } else {
          console.log("error in action");
          console.log(result);
        }
      } else {
        console.log("error in action");
        console.log(result);
      }
      dispatch({ type: SIGN_IN });
    } catch (e) {
      dispatch({ type: SHOW_ERROR, error: e });
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function signOut() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: LOGOUT });
    } catch (e) {
      dispatch({ type: SHOW_ERROR, error: e });
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function resetRedirect() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: RESET_REDIRECT });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function submitUserResponse() {
  return async function (dispatch, getState) {
    try {
      const { user, survey, answers } = getState().user;
      const { uploadedPhotos } = getState().photos;
      dispatch({ type: SHOW_LOADING_USER });
      const apiUser = reduxToApiUser({ user, survey, answers, uploadedPhotos });
      const result = await api.survey.answerSurvey(apiUser);
      if (result.success) {
        const data = result.data;
        dispatch({
          type: SUBMIT_USER_SURVEY,
          redirect: data.redirect
        });
      }
    } catch (e) {
      dispatch({ type: SHOW_ERROR, error: e });
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function getSurveyResponse() {
  return async function (dispatch, getState) {
    try {
      const { survey } = getState().user;
      dispatch({ type: SHOW_LOADING_USER });
      const result = await api.survey.getSurveyResponse(
        survey,
        SurveyType.USER
      );
      if (result.success) {
        const data = result.data;
        dispatch({
          type: GET_USER_SURVEY,
          data
        });
      }
    } catch (e) {
      dispatch({ type: SHOW_ERROR, error: e });
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}
