import React, { useState, useRef } from "react";
import { isMobile } from "react-device-detect";
// Theme
import "./AtomStyles.scss";

export function PhotoUploader({ setImagesToSend }) {
  const inputFile = useRef(null);
  const [previews, setPreview] = useState(null);

  const handleChange = e => {
    if (e.target.files[0]) {
      setPreview(e.target.files);
      setImagesToSend(e.target.files);
    }
  };

  return (
    <div
      className="photo_container"
      onClick={() => inputFile.current.click()}
      style={{ justifyContent: "flex-start" }}
    >
      <div className="upload_container">
        <input
          accept=".jpg,.jpeg,.png"
          className="photo_upload_text"
          type="file"
          onChange={handleChange}
          ref={inputFile}
          style={{ display: "none" }}
          multiple
        />
        {previews ? (
          <div
            style={
              isMobile
                ? {
                    display: "flex",
                    flexDirection: "row",
                    overflowY: "auto",
                    width: "90vw",
                    height: "300px",
                    border: "3px solid #3D4543",
                    borderRadius: "16px"
                  }
                : {
                    display: "flex",
                    flexDirection: "row",
                    overflowY: "auto",
                    minHeight: "500px",
                    maxWidth: "50vw",
                    maxHeight: "80vh",
                    border: "3px solid #3D4543",
                    borderRadius: "16px"
                  }
            }
          >
            {[...previews].splice(0, 5).map((preview, index) => {
              const src = URL.createObjectURL(preview);
              return (
                <img
                  key={index}
                  className="photo"
                  style={
                    isMobile
                      ? { width: "auto", borderRight: "3px solid #3D4543" }
                      : { height: "400px", borderRight: "3px solid #3D4543" }
                  }
                  src={src}
                  alt="Uploaded Images"
                />
              );
            })}
          </div>
        ) : null}
        <div className="base_button_mobile">
          {previews ? "Change the Photo(s)" : "Upload Photo(s)"}
        </div>
      </div>
    </div>
  );
}
