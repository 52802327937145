import { BaseApi } from "./Base";

class PhotoApi extends BaseApi {
  getStyleQuizPics(limit) {
    return super.getApiRequest("/survey/pics", { limit });
  }

  getPhotosFromPath(path) {
    return super.putApiRequest(`/styleQuizPics/`, path);
  }
}

const photoApi = new PhotoApi();

export default photoApi;
