import React, { useState } from "react";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
// Actions
import { RESET_REDIRECT } from "../../redux/actions/Types";

// Components
import Question from "./Question";
import QuestionMobile from "./Question_Mobile";
// Constants
import { giftQuestions } from "../../constants/Questions";
import { SurveyType } from "../../shared/constants/survey";
// Redux
import {
  updateAnswer,
  submitGiftResponse,
  createOrGetUser,
  signOut
} from "../../redux/actions/Gift";
import { getPhotoPickerPhotos } from "../../redux/actions/Photos";
import { trackPageView } from "../../utils/Mixpanel";

let autoNextTimer = 0;

function GiftSurvey(props) {
  const {
    attemptSignOut,
    attemptCreateOrGetUser,
    attemptResetRedirect,
    attemptSubmitSurvey,
    giftAnswers,
    handleQuizChange,
    redirect,
    userId,
    startingQuestion
  } = props;

  const [currentQuestion, setCurrentQuestion] = useState(startingQuestion);
  const [questions, setQuestions] = useState(giftQuestions);
  const question = questions[currentQuestion];

  const isSignedIn = userId !== null;

  const back =
    currentQuestion > 0 ? () => setCurrentQuestion(currentQuestion - 1) : null;

  const next = idx => {
    // console.log(`next question: ${currentQuestion} => ${idx}`);

    trackPageView(SurveyType.GIFT, {
      Viewed_Question: question.name,
      GiftSurveyAnswers: giftAnswers
    });
    setCurrentQuestion(idx);
  };

  const handleNext = selectedAnswer => {
    // console.log(`selected answer: '${selectedAnswer}'`);

    if (!selectedAnswer) {
      if (question.skipQuestion) {
        const previous = giftQuestions.slice(0, currentQuestion + 1);
        const remaining = giftQuestions.slice(currentQuestion + 1);
        const newQuestions = [...previous, question.skipQuestion, ...remaining];

        setQuestions(newQuestions);
      }

      props.attemptUpdateAnswer(question, "");
    } else {
      if (
        question.followupQuestion &&
        question.followupQuestion[selectedAnswer] &&
        question.followupQuestion[selectedAnswer].length > 0
      ) {
        // all follow-up questions of all selected answers
        const followUps = Object.keys(question.followupQuestion)
          .filter(key => selectedAnswer.includes(key))
          .map(key => question.followupQuestion[key])
          .flat(1);

        const previous = giftQuestions.slice(0, currentQuestion + 1);
        const remaining = giftQuestions.slice(currentQuestion + 1);

        const newQuestions = [...previous, ...followUps, ...remaining];
        // console.log("added follow-up questions:", followUps);

        setQuestions(newQuestions);
      }

      props.attemptUpdateAnswer(question, selectedAnswer);
    }

    if (autoNextTimer) {
      window.clearTimeout(autoNextTimer);
      autoNextTimer = 0;
    }

    if (questions[currentQuestion].name === "letsGetGifting" && isSignedIn) {
      attemptSignOut();
    }

    if (
      currentQuestion > 1 &&
      questions[currentQuestion - 1].name === "email" &&
      !isSignedIn
    ) {
      attemptCreateOrGetUser();
    }

    next(currentQuestion + 1);
  };

  if (redirect) {
    window.location = redirect;
    attemptResetRedirect();
  }

  if (question.autoNextTime && !autoNextTimer) {
    autoNextTimer = window.setTimeout(() => {
      handleNext("");
    }, question.autoNextTime * 1000);
  }

  const submit =
    currentQuestion < questions.length - 1 && redirect === null
      ? null
      : () => attemptSubmitSurvey();

  return isMobile ? (
    <QuestionMobile
      question={question}
      back={back}
      next={handleNext}
      skipOption={question.skipOption}
      submit={submit}
      handleQuizChange={handleQuizChange}
      userName={giftAnswers.name}
      previousAnswers={giftAnswers}
    />
  ) : (
    <Question
      question={question}
      back={back}
      next={handleNext}
      skipOption={question.skipOption}
      submit={submit}
      handleQuizChange={handleQuizChange}
      userName={giftAnswers.name}
      previousAnswers={giftAnswers}
    />
  );
}

const mapStateToProps = state => ({
  giftAnswers: state.gift.answers,
  giftTags: state.gift.tags,
  credentials: state.gift.credentials,
  redirect: state.gift.redirect,
  userId: state.gift.user
});

const mapDispatchToProps = dispatch => {
  return {
    attemptUpdateAnswer: (question, answer) =>
      dispatch(updateAnswer(question, answer)),
    attemptGetPhotoPickerPhotos: () => dispatch(getPhotoPickerPhotos()),
    attemptCreateOrGetUser: () => dispatch(createOrGetUser(SurveyType.GIFT)),
    attemptSubmitSurvey: () => dispatch(submitGiftResponse()),
    attemptResetRedirect: () => dispatch({ type: RESET_REDIRECT }),
    attemptSignOut: () => dispatch(signOut())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GiftSurvey);
