import {
  LOGOUT,
  SHOW_ERROR,
  SHOW_LOADING_PHOTOS,
  UPLOAD_SUCCESS,
  UPDATE_PHOTO_PICKER
} from "../actions/Types";
import Immutable from "seamless-immutable";

// initial state

const initialState = Immutable({
  photosLoading: false,
  photoPicker: null,
  uploadedPhotos: []
});

// reducers
const setPhotoPickerPhotos = (state, action) => {
  return Immutable.merge(state, {
    photoPicker: action.data,
    photosLoading: false
  });
};
const setLoadingPhotos = (state, action) => {
  return Immutable.merge(state, {
    photosLoading: true
  });
};
const setError = (state, action) => {
  return Immutable.merge(state, {
    photosLoading: false,
    photoPicker: null
  });
};

const setUploadedPhotos = (state, action) => {
  return Immutable.merge(state, {
    uploadedPhotos: action.data
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PHOTO_PICKER: {
      return setPhotoPickerPhotos(state, action);
    }
    case SHOW_LOADING_PHOTOS:
      return setLoadingPhotos(state, action);
    case SHOW_ERROR:
      return setError(state, action);
    case UPLOAD_SUCCESS:
      return setUploadedPhotos(state, action);
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
