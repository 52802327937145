import React from "react";
// Comnponents
import {
  CheckboxQuestion,
  DateQuestion,
  DescriptionQuestion,
  Message,
  PhotoQuestion,
  PhotoUpload,
  PhotoWithDescriptionQuestion,
  SubmitQuestion,
  TextQuestion,
  ThisOrThatQuestion
} from "../organisms/SurveyMainComponents";
import {
  HorizontalPhotoWithDescription,
  HorizontalSingleChoice
} from "../pages/HorizontalDescription";
import ScrollingPhotoQuestion from "../pages/ScrollingPhoto";
// Constants
import answerType from "../../constants/AnswerTypes";

export default function Question(props) {
  const {
    back,
    credentials,
    handleQuizChange,
    isShowingModal,
    isSignedIn,
    modal,
    next,
    previousAnswers,
    question,
    signOut,
    skipOption,
    submit,
    toggleShowModal,
    uploadedPhotos,
    uploadPhotos,
    errorModal,
    userName
  } = props;

  switch (question.type) {
    case answerType.THIS_OR_THAT:
      return (
        <ThisOrThatQuestion
          back={back}
          skipOption={skipOption}
          question={question}
          next={next}
          submit={submit}
          previousAnswers={previousAnswers}
        />
      );
    case answerType.SCROLLING:
      return (
        <ScrollingPhotoQuestion
          back={back}
          credentials={credentials}
          isShowingModal={isShowingModal}
          isSignedIn={isSignedIn}
          modal={modal}
          next={next}
          previousAnswers={previousAnswers}
          question={question}
          signOut={signOut}
          skipOption={skipOption}
          submit={submit}
          toggleShowModal={toggleShowModal}
        />
      );
    case answerType.TEXT_INPUT:
      return (
        <TextQuestion
          back={back}
          skipOption={skipOption}
          question={question}
          next={next}
          submit={submit}
          previousAnswers={previousAnswers}
          errorModal={errorModal}
        />
      );
    case answerType.PHOTO_DESCRIPTION:
      return (
        <PhotoWithDescriptionQuestion
          back={back}
          question={question}
          skipOption={skipOption}
          next={next}
          submit={submit}
          previousAnswers={previousAnswers}
        />
      );
    case answerType.PHOTO_UPLOAD:
      return (
        <PhotoUpload
          back={back}
          question={question}
          uploadPhotos={uploadPhotos}
          skipOption={skipOption}
          next={next}
          submit={submit}
          previousAnswers={previousAnswers}
          isSignedIn={isSignedIn}
          uploadedPhotos={uploadedPhotos}
        />
      );
    case answerType.DESCRIPTION:
      return (
        <DescriptionQuestion
          back={back}
          question={question}
          next={next}
          submit={submit}
          handleQuizChange={handleQuizChange}
          previousAnswers={previousAnswers}
        />
      );
    case answerType.CHECK_BOX:
      return (
        <CheckboxQuestion
          back={back}
          question={question}
          next={next}
          submit={submit}
          previousAnswers={previousAnswers}
        />
      );
    case answerType.MULTIPLE_HORIZONTAL:
      return (
        <HorizontalPhotoWithDescription
          back={back}
          question={question}
          next={next}
          submit={submit}
          previousAnswers={previousAnswers}
        />
      );
    case answerType.SINGLE_SELECT:
      return (
        <HorizontalSingleChoice
          back={back}
          question={question}
          next={next}
          submit={submit}
          previousAnswers={previousAnswers}
        />
      );
    case answerType.MESSAGE:
      return (
        <Message
          back={back}
          question={question}
          next={next}
          submit={submit}
          userName={userName}
          isSignedIn={isSignedIn}
          previousAnswers={previousAnswers}
        />
      );
    case answerType.DATE:
      return (
        <DateQuestion
          back={back}
          question={question}
          next={next}
          submit={submit}
          previousAnswers={previousAnswers}
        />
      );
    case answerType.SUBMIT:
      return (
        <SubmitQuestion
          back={back}
          question={question}
          next={next}
          submit={submit}
          isSignedIn={isSignedIn}
          userName={userName}
          previousAnswers={previousAnswers}
        />
      );
    default:
      return (
        <PhotoQuestion
          back={back}
          question={question}
          skipOption={skipOption}
          next={next}
          submit={submit}
          previousAnswers={previousAnswers}
        />
      );
  }
}
