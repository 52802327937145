import axios from "axios";
// import { GoogleSignin } from '@react-native-community/google-signin'

import { apiConfig } from "../app.json";

// Base endpoints
import photo from "./Photo";
import user from "./User";
import survey from "./Survey";

const init = () => {
  // initialize axios
  var url = "";
  if (apiConfig.productionLevel === "production") {
    url = apiConfig.productionURI;
  } else {
    if (apiConfig.localTesting) {
      url = apiConfig.localURI;
    } else {
      url = apiConfig.stagingURI;
    }
  }
  axios.defaults.baseURL = url;
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.log("API initialized with URL: ", url);
  }
};

export default {
  init,
  photo,
  user,
  survey
};
