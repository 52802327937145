import {
  HIDE_LOADING,
  SHOW_ERROR,
  SUBMIT_EVENT_SURVEY,
  SET_EVENT_ANSWERS,
  SHOW_LOADING_EVENT,
  CREATE_OR_GET_EVENT
} from "./Types";
import api from "../../api";

import reduxToApiEvent from "../../transforms/reduxEventToApiEvent";

export function updateAnswer(question, answer) {
  return async function (dispatch, getState) {
    try {
      let { answers } = getState().event;
      dispatch({ type: SET_EVENT_ANSWERS, answers, question, answer });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function submitEventResponse() {
  return async function (dispatch, getState) {
    try {
      const { user, survey, answers } = getState().event;
      const { uploadedPhotos } = getState().photos;
      const apiUser = reduxToApiEvent({
        user,
        survey,
        answers,
        uploadedPhotos
      });
      const result = await api.survey.answerSurvey(apiUser);
      if (result.success) {
        const data = result.data;
        dispatch({
          type: SUBMIT_EVENT_SURVEY,
          redirect: data.redirect
        });
      }
    } catch (e) {
      dispatch({ type: SHOW_ERROR });
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function createOrGetUser(type) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING_EVENT });
      let { email, name } = getState().event.answers;

      const result = await api.user.createOrGetUser(email, name, type);
      if (result.success) {
        const data = result.data;
        dispatch({
          type: CREATE_OR_GET_EVENT,
          user: data.user,
          survey: data.survey
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}
