import React, { useState } from "react";
import { isMobile } from "react-device-detect";
// Theme
import "./MoleculeStyles.scss";

// change to styled component
export function GridHorizontal(props) {
  const style = {
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    display: "flex",
    flexDirection: isMobile ? "row" : "row",
    flexWrap: "wrap",
    height: isMobile ? "auto" : "auto",
    justifyContent: "center",
    marginBottom: "5vh",
    marginTop: isMobile ? null : "-10vh",
    maxWidth: props.width ? props.width : "100%",
    minHeight: isMobile ? "50vh" : "auto",
    overflowY: "auto",
    paddingBottom: isMobile ? "80px" : null
  };
  return <div style={style}>{props.children}</div>;
}

export function GridFull(props) {
  const style = {
    marginTop: "5vh",
    height: isMobile ? "auto" : props.height ? props.height : "78vh",
    paddingBottom: isMobile ? "80px" : null,
    width: props.width ? props.width : "100%",
    flexDirection: isMobile ? "column" : "row"
  };
  return (
    <div className="photo_grid_full" style={style}>
      {props.children}
    </div>
  );
}

export function GridStyleDefine(props) {
  const style = {
    height: isMobile ? "auto" : props.height ? props.height : "79vh",
    paddingBottom: isMobile ? "80px" : null,
    width: props.width ? props.width : "100%",
    flexDirection: "row",
    justifyContent: "center"
  };
  return (
    <div className="photo_grid_full" style={style}>
      {props.children}
    </div>
  );
}

export function DescriptionGrid(props) {
  const style = {
    height: isMobile ? "auto" : props.height ? props.height : "79vh",
    width: isMobile ? (props.width ? props.width : "90vw") : "1200px",
    paddingBottom: isMobile ? "80px" : null
  };
  return (
    <div className="description_grid" style={style}>
      {props.children}
    </div>
  );
}

export function ThisOrThat(props) {
  return (
    <div
      className="this_or_that"
      style={
        isMobile
          ? {
              width: "100vw",
              height: "auto",
              flexDirection: "column"
            }
          : { width: "66vw" }
      }
    >
      {props.children}
    </div>
  );
}

export function InputGrid(props) {
  return (
    <div
      className={isMobile ? null : "input_grid"}
      style={
        isMobile
          ? {
              alignItems: "center",
              display: "flex",
              height: "50vh",
              justifyContent: "center",
              padding: "auto",
              width: "auto"
            }
          : { width: "60vw", marginLeft: "3%" }
      }
    >
      {props.children}
    </div>
  );
}

export function GridScroll(props) {
  return (
    <div
      style={
        isMobile
          ? { width: "99vw", marginRight: "0px", maxWidth: "100%" }
          : { width: "66vw", overflowY: "auto" }
      }
      className="photo_grid_scroll"
    >
      {props.children}
    </div>
  );
}

export function GridText({
  answer,
  select,
  value,
  handleChange,
  handleSubmit,
  multiline,
  pressNext,
  hideProgress
}) {
  const [inFocus, setFocus] = useState(false);
  const handleKeyDown = event => {
    if ((event.key === "Enter" || event.key === "Return") && pressNext) {
      pressNext();
    }
  };

  return multiline ? (
    <textarea
      key={answer.description}
      onClick={select}
      onFocus={() => setFocus(true)}
      placeholder={inFocus ? "" : answer.description}
      className="simple_text"
      style={{
        display: "flex",
        width: "80%",
        height: "120px",
        paddingTop: hideProgress ? "calc(5vh + 40px)" : "calc(10vh + 100px)",
        paddingLeft: "10%",
        paddingRight: "10%"
      }}
      value={value}
      onChange={handleChange}
      maxLength={250}
    />
  ) : (
    <input
      key={answer.description}
      onClick={select}
      onFocus={() => setFocus(true)}
      placeholder={inFocus ? "" : answer.description}
      className="simple_text"
      style={{
        display: "flex",
        width: "80%",
        height: "120px",
        paddingTop: hideProgress ? "5vh" : "calc(10vh + 56px)",
        paddingLeft: "10%",
        paddingRight: "10%"
      }}
      value={value}
      onChange={handleChange}
      maxLength={250}
      onKeyDown={handleKeyDown}
    />
  );
}

export function GridHeader({ question }) {
  return <div className="grid_header">{question}</div>;
}

export function NextButton({ next }) {
  return (
    <div className="next" onClick={next}>
      Next
    </div>
  );
}
