import React from "react";
import { isMobile } from "react-device-detect";
// Comnponents
// Constants
import answerType from "../../constants/AnswerTypes";
import sectionType from "../../constants/QuizSection";
// Themes
import "./MoleculeStyles.scss";
import LogoCharcoal from "../../images/Logos/logo-charcoal.svg";

export function SurveyHeader() {
  // todo REMOVE LINKS TO OTHER SURVEYS
  const takeToShopify = () => {
    window.location = "https://layerist.co/";
  };
  return (
    <div className={isMobile ? "survey_header_mobile" : "survey_header"}>
      <div className="to_other_surveys">
        <img
          alt=""
          onClick={takeToShopify}
          src={LogoCharcoal}
          className={isMobile ? "logo_mobile" : "logo"}
        />
      </div>
      <div className={isMobile ? "divider_shadow_mobile" : "divider_shadow"} />
    </div>
  );
}

export function SurveyProgress({ type, name }) {
  var quizSection;
  if (name === "email" || name === "name" || name === "otherEvent") {
    quizSection = sectionType.STYLE;
  } else if (name === "frequency") {
    quizSection = sectionType.DETAILS;
  } else {
    switch (type) {
      case answerType.CHECK_BOX:
        quizSection = sectionType.STYLE;
        break;
      case answerType.DATE:
        quizSection = sectionType.STYLE;
        break;
      case answerType.DESCRIPTION:
        quizSection = sectionType.DETAILS;
        break;
      case answerType.MESSAGE:
        quizSection = sectionType.DETAILS;
        break;
      case answerType.SINGLE_SELECT:
        quizSection = sectionType.LAYERS;
        break;
      case answerType.MULTIPLE_HORIZONTAL:
        quizSection = sectionType.LAYERS;
        break;
      case answerType.PHOTO_DESCRIPTION:
        quizSection = sectionType.STYLE;
        break;
      case answerType.PHOTO_UPLOAD:
        quizSection = sectionType.DETAILS;
        break;
      case answerType.PHOTO:
        quizSection = sectionType.STYLE;
        break;
      case answerType.SCROLLING:
        quizSection = sectionType.STYLE;
        break;
      case answerType.SUMMARY:
        quizSection = sectionType.DETAILS;
        break;
      case answerType.TEXT_INPUT:
        quizSection = sectionType.DETAILS;
        break;
      case answerType.TEXT_OPTION:
        quizSection = sectionType.STYLE;
        break;
      case answerType.THIS_OR_THAT:
        quizSection = sectionType.LAYERS;
        break;
      default:
        quizSection = sectionType.DETAILS;
        break;
    }
  }

  return (
    <div
      className={isMobile ? "survey_progress_mobile" : "survey_progress"}
      autoFocus={isMobile}
      style={
        isMobile
          ? null
          : {
              marginBottom: "5vh"
            }
      }
    >
      <ProgressSection
        active={quizSection === sectionType.STYLE}
        label={sectionType.STYLE}
      />
      <div className="line_outside" />
      <ProgressSection
        active={quizSection === sectionType.LAYERS}
        label={sectionType.LAYERS}
      />
      <div className="line_outside" />
      <ProgressSection
        active={quizSection === sectionType.DETAILS}
        label={sectionType.DETAILS}
      />
    </div>
  );
}

function ProgressSection({ active, label }) {
  const labelStyle = active ? "section_heading_active" : "section_heading";
  const circleStyle = active ? "section_circle_active" : "section_circle";
  const lineStyle = active ? "line_active" : "line";
  const lineStartStyle =
    label === sectionType.STYLE ? "line_inactive" : lineStyle;
  const lineEndStyle =
    label === sectionType.DETAILS ? "line_inactive" : lineStyle;
  return (
    <div className="section_container">
      <div className={labelStyle}>{label}</div>
      <div className="line_container">
        <div className={lineStartStyle} />
        <div>
          <div className={circleStyle} />
        </div>
        <div className={lineEndStyle} />
      </div>
    </div>
  );
}
