import { isMobile } from "react-device-detect";

import Layered from "../images/This or That/Necklaces/1. layered vs strand/layered.png";
import Strand from "../images/This or That/Necklaces/1. layered vs strand/strand.png";
import Bib from "../images/This or That/Necklaces/2. choker vs bib/bib.png";
import Choker from "../images/This or That/Necklaces/2. choker vs bib/choker.png";
import Long from "../images/This or That/Necklaces/3. short pendant vs long pendant/long pendant.png";
import Short from "../images/This or That/Necklaces/3. short pendant vs long pendant/short-pendant.png";
import DelicateNeck from "../images/This or That/Necklaces/4. delicate vs statement necklace/delicate.png";
import StatementNeck from "../images/This or That/Necklaces/4. delicate vs statement necklace/statement.png";
import Bangle from "../images/This or That/Bracelets/7. bead vs bangle/bangle.png";
import Bead from "../images/This or That/Bracelets/7. bead vs bangle/bead.png";
import Clasp from "../images/This or That/Bracelets/8. clasp vs cuff/clasp.png";
import Cuff from "../images/This or That/Bracelets/8. clasp vs cuff/cuff.png";
import Gold from "../images/This or That/Tones/Gold-jewelry.jpg";
import Silver from "../images/This or That/Tones/Silver-jewelry.jpg";
import Colorful from "../images/This or That/Colors/Colorful.png";
import Neutral from "../images/This or That/Colors/Neutral.png";
import Dangle from "../images/This or That/Earrings/5. stud vs dangle/dangle.png";
import Stud from "../images/This or That/Earrings/5. stud vs dangle/stud.png";
import Hoop from "../images/This or That/Earrings/6. hoop vs statement/hoops.png";
import StatmentEaring from "../images/This or That/Earrings/6. hoop vs statement/statement-earrings.png";
import DelicateRing from "../images/This or That/Rings/delicate-ring.png";
import StatmentRing from "../images/This or That/Rings/statement-ring.png";
import NotSure from "../images/PngIcons/NotSure.png";

// SVGs
import Images from "../constants/Images";

// What's your style
import Trendy from "../images/Style Picker/Trendy-StylePicker.jpg";
import Classic from "../images/Style Picker/Classic-StylePicker.jpg";
import Edgy from "../images/Style Picker/Edgy-StylePicker.jpg";
import Preppy from "../images/Style Picker/Preppy-StylePicker.jpg";
import Boho from "../images/Style Picker/Boho-StylePicker.jpg";

import answerType from "./AnswerTypes";
import answerNames from "./AnswerNames";
import tags from "./Tags";
import { validateEmail, validateName, validateSocial } from "../utils/index";

// Shared Constants
// import * as userSurveyQuestions from "../shared/constants/userSurvey";
import * as userSurveyStrings from "./UserSurveyStrings";

export const categories = {
  boho: "boho",
  classic: "classic",
  edgy: "edgy",
  preppy: "preppy",
  trendy: "trendy"
};

const today = new Date();

// Can pick multiple
export const budget = {
  name: answerNames.budget,
  heading:
    "What is the average amount you typically spend on one piece of jewelry?",
  detail: "",
  answers: [
    {
      title: userSurveyStrings.LOW,
      description: "$25 - $75"
    },
    {
      title: userSurveyStrings.MEDIUM,
      description: "$75 - $150"
    },
    {
      title: userSurveyStrings.HIGH,
      description: "$150+"
    }
  ],
  type: answerType.SINGLE_SELECT,
  skipOption: "I don't like any of these, skip",
  options: ["Next"],
  fontStyling: {
    fontFamily: "Sofia Pro Regular",
    marginTop: "20px"
  }
};

export const styleFeel = {
  name: answerNames.styleFeel,
  heading: "How do you define your style?",
  detail: "More than one is okay, there are no right or wrong answers.",
  answers: [
    {
      photo: Classic,
      category: categories.classic,
      title: userSurveyStrings.CLASSIC,
      description: "Timeless, quality, iconic, simple"
    },
    {
      photo: Edgy,
      category: categories.edgy,
      title: userSurveyStrings.EDGY,
      description: "bold, electric, unconventional, confident"
    },
    {
      photo: Boho,
      category: categories.boho,
      title: userSurveyStrings.BOHO,
      description: "natural materials, beads, unique, carefree"
    },
    {
      photo: Preppy,
      category: categories.preppy,
      title: userSurveyStrings.PREPPY,
      description: "pastels, florals, tailored, refined"
    },
    {
      photo: Trendy,
      category: categories.trendy,
      title: userSurveyStrings.TRENDY,
      description: "spontaneous, colorful, easy, fresh"
    },
    {
      photo: NotSure,
      category: categories.trendy,
      title: userSurveyStrings.I_DONT_KNOW,
      description: "let the stylist pick what’s best for me"
    }
  ],
  type: answerType.PHOTO_DESCRIPTION,
  skipOption: false,
  options: ["Next"]
};

export const stylePhotos = {
  name: answerNames.stylePhotos,
  heading: "Tap all the looks that catch your eye.",
  detail: "If you like it, click it.",
  answers: [
    { image: null, category: categories.boho, page: 0 },
    { image: null, category: categories.classic, page: 0 },
    { image: null, category: categories.edgy, page: 0 },
    { image: null, category: categories.preppy, page: 0 },
    { image: null, category: categories.trendy, page: 0 },

    { image: null, category: categories.boho, page: 1 },
    { image: null, category: categories.classic, page: 1 },
    { image: null, category: categories.edgy, page: 1 },
    { image: null, category: categories.preppy, page: 1 },
    { image: null, category: categories.trendy, page: 1 },

    { image: null, category: categories.boho, page: 2 },
    { image: null, category: categories.classic, page: 2 },
    { image: null, category: categories.edgy, page: 2 },
    { image: null, category: categories.preppy, page: 2 },
    { image: null, category: categories.trendy, page: 2 },

    { image: null, category: categories.boho, page: 3 },
    { image: null, category: categories.classic, page: 3 },
    { image: null, category: categories.edgy, page: 3 },
    { image: null, category: categories.preppy, page: 3 },
    { image: null, category: categories.trendy, page: 3 },

    { image: null, category: categories.boho, page: 4 },
    { image: null, category: categories.classic, page: 4 },
    { image: null, category: categories.edgy, page: 4 },
    { image: null, category: categories.preppy, page: 4 },
    { image: null, category: categories.trendy, page: 4 },

    { image: null, category: categories.boho, page: 5 },
    { image: null, category: categories.classic, page: 5 },
    { image: null, category: categories.edgy, page: 5 },
    { image: null, category: categories.preppy, page: 5 },
    { image: null, category: categories.trendy, page: 5 },

    { image: null, category: categories.boho, page: 6 },
    { image: null, category: categories.classic, page: 6 },
    { image: null, category: categories.edgy, page: 6 },
    { image: null, category: categories.preppy, page: 6 },
    { image: null, category: categories.trendy, page: 6 },

    { image: null, category: categories.boho, page: 7 },
    { image: null, category: categories.classic, page: 7 },
    { image: null, category: categories.edgy, page: 7 },
    { image: null, category: categories.preppy, page: 7 },
    { image: null, category: categories.trendy, page: 7 },

    { image: null, category: categories.boho, page: 8 },
    { image: null, category: categories.classic, page: 8 },
    { image: null, category: categories.edgy, page: 8 },
    { image: null, category: categories.preppy, page: 8 },
    { image: null, category: categories.trendy, page: 8 },

    { image: null, category: categories.boho, page: 9 },
    { image: null, category: categories.classic, page: 9 },
    { image: null, category: categories.edgy, page: 9 },
    { image: null, category: categories.preppy, page: 9 },
    { image: null, category: categories.trendy, page: 9 },

    { image: null, category: categories.boho, page: 10 },
    { image: null, category: categories.classic, page: 10 },
    { image: null, category: categories.edgy, page: 10 },
    { image: null, category: categories.preppy, page: 10 },
    { image: null, category: categories.trendy, page: 10 },

    { image: null, category: categories.boho, page: 11 },
    { image: null, category: categories.classic, page: 11 },
    { image: null, category: categories.edgy, page: 11 },
    { image: null, category: categories.preppy, page: 11 },
    { image: null, category: categories.trendy, page: 11 },

    { image: null, category: categories.boho, page: 12 },
    { image: null, category: categories.classic, page: 12 },
    { image: null, category: categories.edgy, page: 12 },
    { image: null, category: categories.preppy, page: 12 },
    { image: null, category: categories.trendy, page: 12 },

    { image: null, category: categories.boho, page: 13 },
    { image: null, category: categories.classic, page: 13 },
    { image: null, category: categories.edgy, page: 13 },
    { image: null, category: categories.preppy, page: 13 },
    { image: null, category: categories.trendy, page: 13 },

    { image: null, category: categories.boho, page: 14 },
    { image: null, category: categories.classic, page: 14 },
    { image: null, category: categories.edgy, page: 14 },
    { image: null, category: categories.preppy, page: 14 },
    { image: null, category: categories.trendy, page: 14 },

    { image: null, category: categories.boho, page: 15 },
    { image: null, category: categories.classic, page: 15 },
    { image: null, category: categories.edgy, page: 15 },
    { image: null, category: categories.preppy, page: 15 },
    { image: null, category: categories.trendy, page: 15 },

    { image: null, category: categories.boho, page: 16 },
    { image: null, category: categories.classic, page: 16 },
    { image: null, category: categories.edgy, page: 16 },
    { image: null, category: categories.preppy, page: 16 },
    // { image: null, category: categories.trendy, page: 16 },

    { image: null, category: categories.boho, page: 17 },
    { image: null, category: categories.classic, page: 17 },
    { image: null, category: categories.edgy, page: 17 },
    { image: null, category: categories.preppy, page: 17 },
    // { image: null, category: categories.trendy, page: 17 },

    // { image: null, category: categories.boho, page: 18 },
    { image: null, category: categories.classic, page: 18 },
    { image: null, category: categories.edgy, page: 18 },
    { image: null, category: categories.preppy, page: 18 },
    // { image: null, category: categories.trendy, page: 18 },

    // { image: null, category: categories.boho, page: 19 },
    { image: null, category: categories.classic, page: 19 },
    { image: null, category: categories.edgy, page: 19 },
    { image: null, category: categories.preppy, page: 19 },
    // { image: null, category: categories.trendy, page: 19 },

    // { image: null, category: categories.boho, page: 20 },
    { image: null, category: categories.classic, page: 20 },
    { image: null, category: categories.edgy, page: 20 },
    { image: null, category: categories.preppy, page: 20 },
    // { image: null, category: categories.trendy, page: 20 },

    // { image: null, category: categories.boho, page: 21 },
    { image: null, category: categories.classic, page: 21 },
    { image: null, category: categories.edgy, page: 21 },
    { image: null, category: categories.preppy, page: 21 },
    // { image: null, category: categories.trendy, page: 21 },

    // { image: null, category: categories.boho, page: 22 },
    { image: null, category: categories.classic, page: 22 },
    // { image: null, category: categories.edgy, page: 22 },
    { image: null, category: categories.preppy, page: 22 }
  ],
  isLogin: true,
  tag: tags.skippedPhotoPicker,
  skipQuestion: styleFeel,
  type: answerType.SCROLLING,
  skipOption: "Skip", // Takes user to login,
  options: ["Skip", "Next"]
};

export const name = {
  name: answerNames.name,
  heading: "By the way, what's your name?",
  detail: "",
  answer: {
    title: "First Name",
    description: "type your name"
  },
  type: answerType.TEXT_INPUT,
  skipOption: false,
  options: ["Next"],
  validation: validateName,
  skipIfSignedIn: true
};

export const pinterest = {
  name: answerNames.pinterest,
  heading: "Let your stylist check out your boards.",
  detail: "Drop a link to your pinterest.",
  answer: {
    title: "Pinterest",
    description: "Pinterest"
  },
  type: answerType.TEXT_INPUT,
  skipOption: "Skip",
  validation: validateSocial,
  options: ["Next"]
};
export const instagram = {
  name: answerNames.instagram,
  heading: "Want us to slide into your DMs? ",
  detail: "Enter your insta handle.",
  answer: {
    title: "Instagram Handle",
    description: "Instagram Handle"
  },
  type: answerType.TEXT_INPUT,
  skipOption: "Skip",
  validation: validateSocial,
  options: ["Next"]
};

export const email = {
  name: answerNames.email,
  heading: "Brilliant! Where should we send your results?",
  detail: "Enter your email to save your profile.",
  answer: {
    title: "e-mail",
    description: "type your e-mail"
  },
  type: answerType.TEXT_INPUT,
  skipOption: false,
  options: ["Next"],
  validation: validateEmail,
  skipIfSignedIn: true
};

export const ringSize = {
  name: answerNames.ringSize,
  heading: "What's your ring size?",
  detail:
    "If you're unsure feel free to select ? and we can work with you to find your size.",
  answers: [
    {
      title: userSurveyStrings.FIVE,
      description: ""
    },
    {
      title: userSurveyStrings.SIX,
      description: ""
    },
    {
      title: userSurveyStrings.SEVEN,
      description: ""
    },
    {
      title: userSurveyStrings.EIGHT,
      description: ""
    },
    {
      title: userSurveyStrings.NINE,
      description: ""
    },
    {
      title: userSurveyStrings.UNKNOWN,
      description: ""
    }
  ],
  type: answerType.SINGLE_SELECT,
  skipOption: "I don't like any of these, skip",
  options: ["Next"]
};

export const layeredVsStrand = {
  name: answerNames.layeredVsStrand,
  heading: "This or That?",
  detail: "Select one, both, or neither.",
  answers: [
    {
      photo: Layered,
      title: userSurveyStrings.LAYERED,
      description: userSurveyStrings.LAYERED
    },
    {
      photo: Strand,
      title: userSurveyStrings.STRAND,
      description: userSurveyStrings.STRAND
    }
  ],
  type: answerType.THIS_OR_THAT,
  skipOption: false,
  options: ["Next"]
};
export const chokerVsBib = {
  name: answerNames.chokerVsBib,
  heading: "This or That?",
  detail: "Select one, both, or neither.",
  answers: [
    {
      photo: Choker,
      title: userSurveyStrings.CHOKER,
      description: userSurveyStrings.CHOKER
    },
    {
      photo: Bib,
      title: userSurveyStrings.BIB,
      description: userSurveyStrings.BIB
    }
  ],
  type: answerType.THIS_OR_THAT,
  skipOption: false,
  options: ["Next"]
};
export const shortVsLong = {
  name: answerNames.shortVsLong,
  heading: "This or That?",
  detail: "Select one, both, or neither.",
  answers: [
    {
      photo: Short,
      title: userSurveyStrings.PENDANT_SHORT,
      description: userSurveyStrings.PENDANT_SHORT
    },
    {
      photo: Long,
      title: userSurveyStrings.PENDANT_LONG,
      description: userSurveyStrings.PENDANT_LONG
    }
  ],
  type: answerType.THIS_OR_THAT,
  skipOption: false,
  options: ["Next"]
};
export const delicateVsStatement = {
  name: answerNames.delicateVsStatement,
  heading: "This or That?",
  detail: "Select one, both, or neither.",
  answers: [
    {
      photo: DelicateNeck,
      title: userSurveyStrings.DELICATE,
      description: userSurveyStrings.DELICATE
    },
    {
      photo: StatementNeck,
      title: `${userSurveyStrings.STATEMENT} Ring`,
      description: userSurveyStrings.STATEMENT
    }
  ],
  type: answerType.THIS_OR_THAT,
  skipOption: false,
  options: ["Next"]
};
export const studVsDangle = {
  name: answerNames.studVsDangle,
  heading: "This or That?",
  detail: "Select one, both, or neither.",
  answers: [
    {
      photo: Stud,
      title: userSurveyStrings.STUD,
      description: userSurveyStrings.STUD
    },
    {
      photo: Dangle,
      title: userSurveyStrings.DANGLE,
      description: userSurveyStrings.DANGLE
    }
  ],
  type: answerType.THIS_OR_THAT,
  skipOption: false,
  options: ["Next"]
};
export const hoopVsStatement = {
  name: answerNames.hoopVsStatement,
  heading: "This or That?",
  detail: "Select one, both, or neither.",
  answers: [
    {
      photo: Hoop,
      title: userSurveyStrings.HOOP,
      description: userSurveyStrings.HOOP
    },
    {
      photo: StatmentEaring,
      title: userSurveyStrings.STATEMENT,
      description: `${userSurveyStrings.STATEMENT} Earring`
    }
  ],
  type: answerType.THIS_OR_THAT,
  skipOption: false,
  options: ["Next"]
};
export const beadVsBangle = {
  name: answerNames.beadVsBangle,
  heading: "This or That?",
  detail: "Select one, both, or neither.",
  answers: [
    {
      photo: Bead,
      title: userSurveyStrings.BEAD,
      description: userSurveyStrings.BEAD
    },
    {
      photo: Bangle,
      title: userSurveyStrings.BANGLE,
      description: userSurveyStrings.BANGLE
    }
  ],
  type: answerType.THIS_OR_THAT,
  skipOption: false,
  options: ["Next"]
};
export const claspVsCuff = {
  name: answerNames.claspVsCuff,
  heading: "This or That?",
  detail: "Select one, both, or neither.",
  answers: [
    {
      photo: Clasp,
      title: userSurveyStrings.CLASP,
      description: userSurveyStrings.CLASP
    },
    {
      photo: Cuff,
      title: userSurveyStrings.CUFF,
      description: userSurveyStrings.CUFF
    }
  ],
  type: answerType.THIS_OR_THAT,
  skipOption: false,
  options: ["Next"]
};
export const delicateVsStatementRing = {
  name: answerNames.delicateVsStatementRing,
  heading: "This or That?",
  detail: "Select one, both, or neither.",
  answers: [
    {
      photo: DelicateRing,
      title: userSurveyStrings.DELICATE,
      description: userSurveyStrings.DELICATE
    },
    {
      photo: StatmentRing,
      title: userSurveyStrings.STATEMENT,
      description: `${userSurveyStrings.STATEMENT} Ring`
    }
  ],
  type: answerType.THIS_OR_THAT,
  skipOption: false,
  options: ["Next"]
};
export const jewelryType = {
  name: answerNames.jewelryType,
  heading: "Which types of jewelry are you looking for?",
  detail: "Select as many as you like.",
  answers: [
    {
      photo: Images.necklace,
      photoAlt: Images.necklaceAlt,
      title: userSurveyStrings.NECKLACES,
      description: userSurveyStrings.NECKLACES
    },
    {
      photo: Images.bracelet,
      photoAlt: Images.braceletAlt,
      title: userSurveyStrings.BRACELETS,
      description: userSurveyStrings.BRACELETS
    },
    {
      photo: Images.earring,
      photoAlt: Images.earringsAlt,
      title: userSurveyStrings.EARRINGS,
      description: userSurveyStrings.EARRINGS
    },
    {
      photo: Images.ring,
      photoAlt: Images.ringAlt,
      title: userSurveyStrings.RINGS,
      description: userSurveyStrings.RINGS
    }
  ],
  followupQuestion: {
    Necklaces: [layeredVsStrand, chokerVsBib, shortVsLong, delicateVsStatement],
    Earrings: [studVsDangle, hoopVsStatement],
    Bracelets: [beadVsBangle, claspVsCuff],
    Rings: [delicateVsStatementRing, ringSize]
  },
  type: answerType.MULTIPLE_HORIZONTAL,
  skipOption: false,
  options: ["Next"]
};

export const goldVsSilver = {
  name: answerNames.goldVsSilver,
  heading: "This or That?",
  detail: "Select one or both.",
  answers: [
    {
      photo: Gold,
      title: userSurveyStrings.GOLD,
      description: userSurveyStrings.GOLD
    },
    {
      photo: Silver,
      title: userSurveyStrings.SILVER,
      description: userSurveyStrings.SILVER
    }
  ],
  type: answerType.THIS_OR_THAT,
  skipOption: false,
  neitherDisabled: true,
  options: ["Next"]
};
export const colorfulVsNeutral = {
  name: answerNames.colorfulVsNeutral,
  heading: "This or That?",
  detail: "Select one or both.",
  answers: [
    {
      photo: Colorful,
      title: userSurveyStrings.COLORFUL,
      description: userSurveyStrings.COLORFUL
    },
    {
      photo: Neutral,
      title: userSurveyStrings.NEUTRAL,
      description: userSurveyStrings.NEUTRAL
    }
  ],
  type: answerType.THIS_OR_THAT,
  skipOption: false,
  neitherDisabled: true,
  options: ["Next"]
};
export const frequency = {
  name: answerNames.frequency,
  heading: "How often should we send you a box?",
  detail: "",
  answers: [
    // {
    //   title: userSurveyStrings.ONCE_MONTHLY
    // },
    {
      title: userSurveyStrings.EVERY_OTHER_MONTH
    },
    {
      title: userSurveyStrings.EVERY_THREE_MONTHS
    }
  ],
  type: answerType.SINGLE_SELECT,
  skipOption: "I don't like any of these, skip",
  options: ["Next"],
  fontStyling: isMobile
    ? { fontSize: "22px", lineHeight: "26px", padding: "5px" }
    : { fontSize: "22px", lineHeight: "30px", padding: "15px" }
};
export const layerItOn = {
  name: answerNames.layerItOn,
  heading: "Layer it on! Shall we include any other accessories?",
  detail: "",
  answers: [
    {
      photo: Images.handbag,
      photoAlt: Images.handbagAlt,
      title: userSurveyStrings.HANDBAG,
      description: userSurveyStrings.HANDBAG
    },
    {
      photo: Images.scarf,
      photoAlt: Images.scarfAlt,
      title: userSurveyStrings.SCARF,
      description: userSurveyStrings.SCARF
    },
    {
      photo: Images.hair,
      photoAlt: Images.hairAlt,
      title: userSurveyStrings.HAIR_ACCESSORY,
      description: userSurveyStrings.HAIR_ACCESSORY
    }
  ],
  type: answerType.MULTIPLE_HORIZONTAL,
  skipOption: "Skip",
  options: ["Next"]
};

export const stylistDetails = {
  name: answerNames.stylistDetails,
  heading: "Anything else you want your stylist to know?",
  detail: "",
  answer: {
    title: "Details",
    description: "I'd like my stylist to know..."
  },
  type: answerType.TEXT_INPUT,
  skipOption: "Skip",
  options: ["Next"],
  multiline: true
};
export const addEventBox = {
  name: answerNames.addEventBox,
  heading: "Shall we add another event box today?",
  detail: "",
  answers: [
    {
      title: userSurveyStrings.NOT_RIGHT_NOW,
      description: ""
    },
    {
      title: userSurveyStrings.YES,
      description: ""
    }
  ],
  type: answerType.DESCRIPTION,
  skipOption: false,
  options: ["Next"]
};
export const bigEvent = {
  name: answerNames.bigEvent,
  heading: "Do you have any events coming up?",
  detail: "Click here to sign up for an event box.",
  answers: [
    {
      title: userSurveyStrings.NOT_RIGHT_NOW,
      description: ""
    },
    {
      title: userSurveyStrings.YES,
      description: ""
    }
  ],
  changeQuiz: "Event",
  type: answerType.DESCRIPTION,
  skipOption: false,
  options: ["Next"]
};
export const photoUpload = {
  name: answerNames.photoUpload,
  heading: "Add a photo or two to perfect your styling!",
  detail: "",
  answers: [
    {
      photoUrl: ""
    }
  ],
  type: answerType.PHOTO_UPLOAD,
  skipOption: "Skip",
  options: ["Next"]
};
export const stylingTips = {
  name: answerNames.stylingTips,
  heading: "Sign up for our styling tips and trends?",
  detail: "",
  answers: [
    {
      title: userSurveyStrings.NO_THANK_YOU,
      description: ""
    },
    {
      title: userSurveyStrings.YES_PLEASE,
      description: ""
    }
  ],
  type: answerType.DESCRIPTION,
  skipOption: false,
  options: ["Next"]
};
export const birthday = {
  name: answerNames.birthday,
  heading: "Enter your birthday for a special treat",
  detail: "",
  answer: {
    title: "Birthday",
    description: ""
  },
  type: answerType.DATE,
  skipOption: false,
  options: ["Next"],
  dateRequirement: new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 14
  )
};
export const stylingVideo = {
  name: answerNames.stylingVideo,
  heading: "How about a video styling session?",
  detail:
    "First session is on us. Check here and your stylist will set something up.",
  answers: [
    {
      title: userSurveyStrings.NO_THANK_YOU,
      description: ""
    },
    {
      title: userSurveyStrings.YES_PLEASE,
      description: ""
    }
  ],
  type: answerType.DESCRIPTION,
  skipOption: false,
  options: ["Next"]
};
export const gettingToKnowYou = {
  name: answerNames.gettingToKnowYou,
  isSubmit: true,
  heading: "It's been a pleasure getting to know you, ",
  detail:
    "Your Layerist Signature Kit will be added to your shopping cart and we'll start styling once you complete checkout!",
  answers: [
    {
      title: "Check your inbox",
      description: ""
    }
  ],
  type: answerType.SUBMIT,
  skipOption: false,
  options: ["Next"],
  isName: true,
  messageKey: "name"
};
export const summaryPage = {
  name: answerNames.summaryPage,
  heading: "Here's a summary of your stats",
  detail: "Upload or take a photo of yourself.",
  answers: [
    {
      style: "50% classic"
    }
  ],
  type: answerType.SUMMARY,
  skipOption: "Skip",
  options: ["Next"]
};
