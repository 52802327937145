exports.SurveyType = {
  USER: "USER",
  GIFT: "GIFT",
  EVENT: "EVENT",
};

exports.SurveyProgress = {
  INCOMPLETE: "INCOMPLETE",
  COMPLETE: "COMPLETE",
};

exports.StyleFeel = {
  CLASSIC: "CLASSIC",
  EDGY: "EDGY",
  BOHO: "BOHO",
  PREPPY: "PREPPY",
  TRENDY: "TRENDY",
  I_DONT_KNOW: "I_DONT_KNOW",
};

exports.JewelryType = {
  NECKLACES: "NECKLACES",
  EARRINGS: "EARRINGS",
  BRACELETS: "BRACELETS",
  RINGS: "RINGS",
};

exports.BudgetSize = {
  $: "$",
  $$: "$$",
  $$$: "$$$",
};

exports.BudgetPrice = {
  75: "75",
  100: "100",
  150: "150",
  200: "200",
  250: "250",
  300: "300",
};

exports.ECardPrice = {
  10: "10",
  25: "25",
  50: "50",
  100: "100",
  200: "200",
};

exports.LayerOption = {
  HANDBAG: "HANDBAG",
  SCARF: "SCARF",
  HAIR_ACCESSORY: "HAIR_ACCESSORY",
  HOME_ACCESSORY: "HOME_ACCESSORY",
};
