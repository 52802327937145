/* QUIZ PHOTOS */
export const UPDATE_EVENT_PHOTOS = "UPDATE_EVENT_PHOTOS";
export const UPDATE_PHOTO_PICKER = "UPDATE_PHOTO_PICKER";
export const UPDATE_STYLE_PICKER = "UPDATE_STYLE_PICKER";
export const UPDATE_THIS_OR_THAT = "UPDATE_THIS_OR_THAT";
export const UPDATE_USER_QUIZ = "UPDATE_USER_QUIZ";
export const UPDATE_EVENT_QUIZ = "UPDATE_EVENT_QUIZ";
export const UPDATE_GIFT_QUIZ = "UPDATE_GIFT_QUIZ";

/* USER ANSWERS */
export const CREATE_OR_GET = "CREATE_OR_GET";
export const GET_USER_ANSWERS = "GET_USER_ANSWERS";
export const SET_USER_ANSWERS = "SET_USER_ANSWERS";
export const GET_GIFT_ANSWERS = "GET_GIFT_ANSWERS";
export const SET_GIFT_ANSWERS = "SET_GIFT_ANSWERS";
export const SET_EVENT_ANSWERS = "SET_EVENT_ANSWERS";
export const GET_EVENT_ANSWERS = "GET_EVENT_ANSWERS";
export const SUBMIT_USER_SURVEY = "SUBMIT_USER_SURVEY";
export const GET_USER_SURVEY = "GET_USER_SURVEY";

/* EVENT */
export const SUBMIT_EVENT_SURVEY = "SUBMIT_EVENT_SURVEY";
export const SUBMIT_E_CARD = "SUBMIT_E_CARD";
export const CREATE_OR_GET_EVENT = "CREATE_OR_GET_EVENT";
export const SHOW_LOADING_EVENT = "SHOW_LOADING_EVENT";

/* GIFT */
export const SUBMIT_GIFT_SURVEY = "SUBMIT_GIFT_SURVEY";
export const SHOW_LOADING_GIFT = "SHOW_LOADING_GIFT";
export const CREATE_OR_GET_GIFT = "CREATE_OR_GET_GIFT";

/* SHARED */
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const SHOW_ERROR = "SHOW_ERROR";
export const SHOW_LOADING_USER = "SHOW_LOADING_USER";
export const SHOW_LOADING_PHOTOS = "SHOW_LOADING_PHOTOS";
export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const HIDE_LOADING_PHOTOS = "HIDE_LOADING_PHOTOS";
export const HIDE_ERROR = "HIDE_ERROR";
export const HIDE_LOADING = "HIDE_LOADING";
export const SUCCESS = "SUCCESS";

/* ACCOUNT */
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const UPDATE_ROUTES = "UPDATE_ROUTES";
export const SHOW_DIALOGUE = "SHOW_DIALOGUE";
export const TOGGLE_SELF_SERVICE = "TOGGLE_SELF_SERVICE";

/* EMAIL */
export const SEND_EMAIL_USER = "SEND_EMAIL_USER";

/* DATA */
export const REQUEST_DATA = "REQUEST_DATA";

/* ONBOARDING */
export const LOGOUT = "LOGOUT";
export const RESET_REDIRECT = "RESET_REDIRECT";
