import { BaseApi } from "./Base";

class SurvayApi extends BaseApi {
  answerSurvey(survey) {
    return super.postApiRequest("/survey", survey);
  }
  getSurveyResponse(survey, type) {
    return super.postApiRequest("/survey/userResponse", { survey, type });
  }
  answerGiftSurvey(survey) {
    return super.postApiRequest("/survey/gift", survey);
  }
}

const surveyApi = new SurvayApi();

export default surveyApi;
