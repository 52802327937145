import {
  HIDE_LOADING,
  SHOW_ERROR,
  SHOW_LOADING_GIFT,
  SUBMIT_GIFT_SURVEY,
  SET_GIFT_ANSWERS,
  LOGOUT,
  CREATE_OR_GET_GIFT
} from "./Types";
import api from "../../api";
import reduxToApiGift from "../../transforms/reduxGiftApiGift";
// constants

export function updateAnswer(question, answer) {
  return async function (dispatch, getState) {
    try {
      let { answers } = getState().gift;
      dispatch({ type: SET_GIFT_ANSWERS, answers, question, answer });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function signOut() {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: LOGOUT });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function submitGiftResponse() {
  return async function (dispatch, getState) {
    try {
      const { user, survey, answers } = getState().gift;

      const apiGift = reduxToApiGift({ user, survey, answers });
      const giftSurvey = await api.survey.answerGiftSurvey(apiGift);
      if (giftSurvey.success) {
        const data = giftSurvey.data;

        dispatch({
          type: SUBMIT_GIFT_SURVEY,
          redirect: data.redirect
        });
      } else {
        dispatch({ SHOW_ERROR });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}

export function createOrGetUser(type) {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: SHOW_LOADING_GIFT });
      let { email, name } = getState().gift.answers;
      const result = await api.user.createOrGetUser(email, name, type);
      if (result.success) {
        const data = result.data;
        dispatch({
          type: CREATE_OR_GET_GIFT,
          user: data.user,
          survey: data.survey
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING });
    }
  };
}
