import SurveyStringsToKeys from "../constants/SurveyStringsToKeys";

const reduxToApiUser = userState => {
  const { user, survey, answers, uploadedPhotos } = userState;
  const apiUser = {
    user: user,
    survey: survey,
    photoUpload: uploadedPhotos ? uploadedPhotos : [],
    ...filterAnswers(answers)
  };
  return apiUser;
};

const filterAnswers = stateAnswers => {
  const filteredAnswers = {};
  Object.keys(stateAnswers).forEach(key => {
    const value = stateAnswers[key];

    if (value !== null && value !== "") {
      if (typeof value === "object") {
        switch (key) {
          case "styleFeel":
            filteredAnswers[key] = Object.values(value).map(
              val => SurveyStringsToKeys[val]
            );
            break;
          case "jewelryType":
            filteredAnswers["jewelryType"] = Object.values(value).map(
              val => SurveyStringsToKeys[val]
            );
            break;
          case "layerItOn":
            filteredAnswers[key] = Object.values(value).map(
              val => SurveyStringsToKeys[val]
            );
            break;
          case "stylePhotos":
            filteredAnswers[key] = value;
            break;
          default:
            Object.values(value).forEach(val => {
              filteredAnswers[key] = SurveyStringsToKeys[val];
            });
            break;
        }
      } else {
        switch (key) {
          case "email":
            break;
          case "name":
            break;
          case "styleFeel":
            filteredAnswers[key] = Object.values(value).map(
              val => SurveyStringsToKeys[val]
            );
            break;
          case "budget":
            filteredAnswers["budget"] = SurveyStringsToKeys[value];
            break;
          default:
            filteredAnswers[key] = SurveyStringsToKeys[value]
              ? SurveyStringsToKeys[value]
              : value;
            break;
        }
      }
    }
  });
  /* Got this error from backend:
   "Update() requires either a single JavaScript object or an alternating list of field/value pairs that can be followed by an optional precondition. Value for argument \"dataOrField\" is not a valid Firestore value. Cannot use \"undefined\" as a Firestore value (found in field \"styleFeel\"). If you want to ignore undefined values, enable `ignoreUndefinedProperties`."
   */
  if (
    stateAnswers["styleFeel"] === undefined ||
    stateAnswers["styleFeel"] === null
  ) {
    filteredAnswers["styleFeel"] = ["I_DONT_KNOW"];
  }
  return filteredAnswers;
};

export default reduxToApiUser;

// export const UNKNOWN = unknown
