import mixpanel from "mixpanel-browser";

export function trackPageView(currentSurvey, analytics) {
  const eventName = `VIEWED ${currentSurvey}_SURVEY QUESTION: ${analytics.Viewed_Question.toUpperCase()}`;
  const result = mixpanel.track(eventName, {
    ...analytics
  });
  return result;
}

export function setOrGetUserAnalytics(availableUserDetails) {
  const { email, user, name, survey } = availableUserDetails;
  const userDetails = {
    $email: email, // only reserved properties need the $
    USER_ID: user, // use human-readable names
    Name: name,
    SurveyId: survey ? survey : "null" // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
  };
  mixpanel.people.set(userDetails);
  mixpanel.identify(userDetails.USER_ID);

  return mixpanel;
}
