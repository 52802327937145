import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { isBrowser, isMobile } from "react-device-detect";
// Comnponents
import { GridPhoto, GridPhotoThisThat } from "../atoms/Photos";
import { PhotoUploader } from "../atoms/PhotoUpload";
import {
  GridFull,
  DescriptionGrid,
  GridText,
  ThisOrThat,
  InputGrid
} from "../molecules/Grid";
import {
  SurveyFooter,
  TextFooter,
  ThisThatFooter,
  Submit
} from "../molecules/Footer";
import { SurveyQuestion, SurveyMessage } from "../molecules/Question";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Themes
import "./OrganismStyles.scss";

export function PhotoUpload(props) {
  const { back, next, skipOption, question, uploadPhotos } = props;
  const [imagesToSend, setImagesToSend] = useState(null);

  const handleNext = () => {
    if (imagesToSend !== null) {
      uploadPhotos(imagesToSend);
      next([]);
    } else {
      next([]);
    }
  };

  return (
    <div
      className="answer_container"
      style={isMobile ? { flexDirection: "column" } : null}
    >
      <SurveyQuestion question={question} />
      <PhotoUploader imagesToSend={imagesToSend} setImages={setImagesToSend} />
      <SurveyFooter
        back={back}
        skipOption={skipOption}
        next={handleNext}
        canProceed={imagesToSend !== null}
      />
    </div>
  );
}

const ValidationMessage = styled.div`
  color: red;
  margin-top: 10px;
  align-self: center;
`;

export function TextQuestion(props) {
  const { back, next, skipOption, question, previousAnswers } = props;
  const previousAnswer = previousAnswers[question.name];
  const [value, changeValue] = useState(previousAnswer ? previousAnswer : "");
  if (value === null) {
    changeValue(previousAnswer ? previousAnswer : "");
  }
  const [isValid, setIsValid] = useState(true);
  const [canProceed, setCanProceed] = useState(false);
  const handleSubmit = evt => {
    evt.preventDefault();
    alert(`Submitting Name ${value}`);
  };
  const handleNext = () => {
    const oldVal = value;
    changeValue(null);
    next(oldVal);
  };
  const handleSkip = () => {
    next("");
    changeValue(null);
  };
  const handleBack = () => {
    const oldVal = value;
    changeValue(null);
    back(oldVal);
  };
  const handleChange = e => changeValue(e.target.value);
  useEffect(() => {
    const valid =
      question.validation !== undefined
        ? question.validation(value)
        : value !== "";
    setIsValid(true);
    const timeoutId = setTimeout(() => {
      setIsValid(valid);
      setCanProceed(valid);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [question, value]);

  const submit = question.isSubmit
    ? () => {
        const oldVal = value;
        changeValue(null);
        props.submit(oldVal);
      }
    : null;

  return (
    <div
      key={question.name}
      className={
        isMobile ? "answer_container_text_mobile" : "answer_container_text"
      }
    >
      {props.errorModal}
      <SurveyQuestion question={question} />
      <InputGrid>
        <GridText
          key={previousAnswer}
          multiline={question.multiline}
          answer={question.answer}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          value={value}
          pressNext={canProceed ? handleNext : null}
          hideProgress={question.hideProgress}
        />
        {isValid || value === "" ? null : (
          <ValidationMessage>Invalid answer</ValidationMessage>
        )}
        <TextFooter
          back={handleBack}
          skipOption={skipOption}
          handleSkip={handleSkip}
          next={handleNext}
          submit={submit}
          canProceed={canProceed}
        />
      </InputGrid>
    </div>
  );
}

const ThisThatPictureContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

export function ThisOrThatQuestion(props) {
  const { back, next, skipOption, question } = props;
  const canProceed = true;

  return (
    <div className="answer_container">
      <SurveyQuestion question={question} />
      <ThisOrThat>
        <ThisThatPictureContainer>
          {question.answers.map((answer, index) => {
            // const isSelected = selected === answer.title;
            const onSelect = () => {
              next(answer.title);
            };
            return (
              <div className="this_that_container" key={index}>
                <GridPhotoThisThat
                  isSelected={false}
                  select={onSelect}
                  src={answer.photo}
                />
                <div className="answer_description_this_that">
                  {answer.description}
                </div>
              </div>
            );
          })}
        </ThisThatPictureContainer>
        <ThisThatFooter
          neitherDisabled={question.neitherDisabled}
          back={back}
          skipOption={skipOption}
          next={next}
          canProceed={canProceed}
        />
      </ThisOrThat>
      <SurveyFooter back={back} />
    </div>
  );
}

export function PhotoWithDescriptionQuestion(props) {
  const { back, next, skipOption, question, previousAnswers } = props;
  let previousAnswer = [];
  if (previousAnswers) {
    previousAnswer =
      previousAnswers[question.name] !== null
        ? previousAnswers[question.name]
        : [];
  }

  let obj = question.answers.map(answer => {
    return previousAnswer.includes(answer.title);
  });
  const [selected, setSelected] = useState(obj);
  const [selectedTitles, setSelectedTitle] = useState([...previousAnswer]);
  const handleNext = () => {
    next(selectedTitles);
    setSelectedTitle([]);
    setSelected([]);
  };
  const numberOfAnswers = question.answers.length;
  const columnWidth =
    numberOfAnswers === 4
      ? "45vw"
      : numberOfAnswers === 6
      ? "65vw"
      : numberOfAnswers === 5
      ? "60vw"
      : "60vw";

  const canProceed = selectedTitles.length > 0;
  return (
    <div
      className="answer_container_scroll"
      style={
        isMobile
          ? { width: "90vw", flexDirection: "column" }
          : {
              width: "auto"
            }
      }
    >
      <SurveyQuestion question={question} />
      <GridFull
        question={question}
        width={isMobile ? null : columnWidth}
        height="auto"
      >
        {question.answers.map((answer, index) => {
          const isSelected = selected[index];
          const title = answer.title;
          const onSelect = () => {
            let newObj = selected;
            if (question.singleSelect) {
              next(title);
              setSelectedTitle([]);
              setSelected([]);
            } else if (selectedTitles.includes(answer.title)) {
              selectedTitles.pop(answer.title);
            } else {
              selectedTitles.push(answer.title);
            }
            let selectedTitleUpdate = selectedTitles;
            newObj[index] = !selected[index];
            setSelected({ ...newObj });
            setSelectedTitle(selectedTitleUpdate);
          };
          return (
            <div className="description_container third" key={index}>
              <GridPhoto
                isSelected={isSelected}
                select={onSelect}
                src={
                  answer.photoAlt && isSelected ? answer.photoAlt : answer.photo
                }
              />
              <div className="answer_title">{answer.title}</div>
              {answer.description ? (
                <div className="answer_description">{answer.description}</div>
              ) : null}
            </div>
          );
        })}
      </GridFull>
      <SurveyFooter
        back={back}
        skipOption={skipOption}
        next={question.singleSelect ? null : handleNext}
        canProceed={canProceed}
      />
    </div>
  );
}

export function DescriptionQuestion(props) {
  const { back, next, skipOption, question, previousAnswers } = props;
  let previousAnswer = "";
  if (previousAnswers) {
    previousAnswer = previousAnswers[question.name]
      ? previousAnswers[question.name]
      : "";
  }
  const obj = question.answers.map(answer => answer.title === previousAnswer);
  const initialSelectedTitle = previousAnswer;
  const [selected, setSelected] = useState(obj);
  const [selectedTitle, setSelectedTitle] = useState(initialSelectedTitle);
  const handleNext = () => {
    const tempAnswer = selectedTitle;
    setSelectedTitle("");
    setSelected([]);
    next(tempAnswer);
  };

  const canProceed = selectedTitle !== "";
  const handleSubmit =
    question.redirect && question.redirectUrl && selectedTitle === "E-Card"
      ? () => {
          window.location = question.redirectUrl;
        }
      : question.isSubmit
      ? () => {
          props.submit();
        }
      : null;

  return (
    <div
      className="answer_container_scroll"
      style={isMobile ? { width: "90vw", flexDirection: "column" } : {}}
    >
      <SurveyQuestion question={question} />
      <DescriptionGrid>
        <div
          className="description_row"
          style={
            question.answers.length < 4 && isMobile
              ? { flexDirection: "column", alignItems: "center" }
              : { flexDirection: "row" }
          }
        >
          {question.answers.slice(0, 3).map((answer, index) => {
            const isSelected = selected[index];
            const onSelect = () => {
              let newObj = question.answers.map(() => false);
              newObj[index] = true;
              setSelected(newObj);
              setSelectedTitle(answer.title);
            };
            return (
              <div
                className={
                  isSelected
                    ? "simple_description_selected"
                    : "simple_description"
                }
                style={isMobile ? { height: "100px", width: "220px" } : {}}
                key={index}
                onClick={onSelect}
              >
                <div
                  className={
                    isSelected
                      ? "description_title_selected"
                      : "description_title"
                  }
                >
                  {answer.title}
                </div>
              </div>
            );
          })}
        </div>
        <div className="description_row">
          {question.answers.slice(3).map((answer, index) => {
            const isSelected = selected[index + 3];
            const onSelect = () => {
              let newObj = question.answers.map(() => false);
              newObj[index + 3] = true;
              setSelected(newObj);
              setSelectedTitle(answer.title);
            };
            return (
              <div
                className={
                  isSelected
                    ? "simple_description_selected"
                    : "simple_description"
                }
                style={isMobile ? { height: "100px", width: "100px" } : {}}
                key={index}
                onClick={onSelect}
              >
                <div
                  className={
                    isSelected
                      ? "description_title_selected"
                      : "description_title"
                  }
                >
                  {answer.title}
                </div>
              </div>
            );
          })}
        </div>
      </DescriptionGrid>
      <SurveyFooter
        canProceed={canProceed}
        next={handleNext}
        back={back}
        skipOption={skipOption}
        submit={handleSubmit}
      />
    </div>
  );
}

export function PhotoQuestion(props) {
  const { back, next, skipOption, question } = props;
  const obj = question.answers.map(() => false);
  const [selected, setSelected] = useState(obj);
  const [selectedAnswer, selectAnswer] = useState("");
  const handleNext = () => {
    next(selectedAnswer);
  };
  const canProceed = selectedAnswer !== "";

  return (
    <div className="answer_container">
      <SurveyQuestion question={question} />
      <GridFull>
        {question.answers.map((answer, index) => {
          const isSelected = selected[index];
          const onSelect = () => {
            let newObj = selected;
            newObj[index] = !selected[index];
            setSelected({ ...newObj });
            selectAnswer(answer.title);
          };
          return (
            <GridPhoto
              key={index}
              select={onSelect}
              isSelected={isSelected}
              src={answer}
            />
          );
        })}
      </GridFull>
      <SurveyFooter
        skipOption={skipOption}
        back={back}
        next={handleNext}
        canProceed={canProceed}
      />
    </div>
  );
}

export function CheckboxQuestion(props) {
  const { back, next, skipOption, question } = props;
  const [selected, setSelected] = useState(false);
  const handleNext = () => {
    next(selected);
  };
  const canProceed = selected !== false;
  return (
    <div className="answer_container">
      <SurveyQuestion question={question} />
      <GridFull>
        <div
          className="checkbox_container"
          onClick={() => setSelected(!selected)}
        >
          <div className="checkbox_text">Yes</div>
          <div
            className={selected ? "checkbox_answer_active" : "checkbox_answer"}
          />
        </div>
      </GridFull>
      <SurveyFooter
        skipOption={skipOption}
        back={back}
        next={handleNext}
        canProceed={canProceed}
      />
    </div>
  );
}

export function Message(props) {
  const { back, next, skipOption, question, userName } = props;
  const handleNext = () => {
    next("");
  };
  const handleSubmit = question.isSubmit
    ? () => {
        props.submit();
      }
    : null;

  return (
    <div className="answer_container_this_or_that">
      <SurveyMessage question={question} userName={userName} />
      <SurveyFooter
        skipOption={skipOption}
        back={back}
        next={handleNext}
        submit={handleSubmit}
        canProceed
      />
    </div>
  );
}

export function DateQuestion(props) {
  const { back, next, skipOption, question, previousAnswers } = props;
  const previousAnswer = previousAnswers[question.name]
    ? new Date(previousAnswers[question.name])
    : new Date(question.dateRequirement);
  const [date, changeDate] = useState(previousAnswer);
  const canProceed = date >= question.dateRequirement;
  const handleNext = () => {
    next(
      `${new Date(date).getMonth() + 1}/${new Date(date).getDate()}/${new Date(
        date
      ).getFullYear()}`
    );
  };
  const handleChange = newDate => {
    changeDate(newDate);
  };
  return (
    <div
      className={
        isMobile ? "answer_container_text_mobile" : "answer_container_text"
      }
    >
      <SurveyQuestion question={question} />
      <InputGrid>
        <div
          className="date_picker_container"
          style={
            isMobile ? { width: "99vw", fontSize: "22px" } : { width: "46vw" }
          }
        >
          <DatePicker
            className={isMobile ? "date_picker_mobile" : "date_picker"}
            selected={date}
            onChange={handleChange}
          />
        </div>
        <TextFooter
          back={back}
          skipOption={skipOption}
          next={handleNext}
          canProceed={canProceed}
        />
      </InputGrid>
    </div>
  );
}

export function SubmitQuestion(props) {
  const { back, question, userName, submit } = props;
  const { heading, detail, isName } = question;
  // This is so that the button isn't slightly pushed right on web.
  const browserStyle = isBrowser ? { paddingRight: "30px" } : null;
  return (
    <div className="submit_message_container">
      <div className="message_container">
        <div className="question_text_container">
          <h1>
            {heading}
            {isName && userName
              ? `${userName[0].toUpperCase()}${userName
                  .slice(1)
                  .toLowerCase()}!`
              : ""}
          </h1>
          <div className="submit_button_container" style={browserStyle}>
            <Submit submit={submit} />
          </div>
          {detail ? <h2>{detail}</h2> : null}
        </div>
      </div>
      <SurveyFooter back={back} />
    </div>
  );
}
