export default {
  CHECK_BOX: "CHECK_BOX",
  DATE: "DATE",
  DESCRIPTION: "DESCRIPTION",
  MESSAGE: "MESSAGE",
  SINGLE_SELECT: "SINGLE_SELECT",
  MULTIPLE_HORIZONTAL: "MULTIPLE_HORIZONTAL",
  PHOTO_DESCRIPTION: "PHOTO_DESCRIPTION",
  PHOTO_UPLOAD: "PHOTO_UPLOAD",
  PHOTO: "PHOTO",
  SCROLLING: "SCROLLING",
  SUMMARY: "SUMMARY",
  TEXT_INPUT: "TEXT_INPUT",
  TEXT_OPTION: "TEXT_OPTION",
  THIS_OR_THAT: "THIS_OR_THAT",
  SUBMIT: "SUBMIT"
};
