// Style photos
import Cocktail from "../images/Event/cocktail.jpg";
import Gala from "../images/Event/gala.jpg";
import Vacation from "../images/Event/vacation.jpg";
import Wedding from "../images/Event/wedding.jpg";
import Other from "../images/PngIcons/Other.png";

// SVGs
import Images from "../constants/Images";

import answerType from "./AnswerTypes";
import answerNames from "./AnswerNames";

const today = new Date();

export const eventDate = {
  name: answerNames.eventDate,
  heading: "When is your event?",
  detail: "We need at least two weeks to select your pieces",
  answer: {
    title: "Event Date",
    description: ""
  },
  type: answerType.DATE,
  skipOption: false,
  options: ["Next"],
  dateRequirement: new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 14
  )
};

export const evtName = {
  name: answerNames.evtName,
  heading: "What should we call you, by the way?",
  detail: "Enter your name here.",
  answer: {
    title: "First Name",
    description: "type your name"
  },
  type: answerType.TEXT_INPUT,
  skipOption: false,
  options: ["Next"]
};

export const weddingDetails = {
  name: answerNames.weddingDetails,
  heading: "In the Wedding",
  detail: "Are you:",
  answers: [
    {
      title: "The Bride"
    },
    {
      title: "The Bridesmaid"
    },
    {
      title: "A Guest"
    },
    {
      title: "Mother of the Groom"
    },
    {
      title: "Mother of the Bride"
    },
    {
      title: "Other"
    }
  ],
  type: answerType.SINGLE_SELECT,
  skipOption: false,
  options: ["Next"],
  fontStyling: {
    fontSize: "16px",
    lineHeight: "24px"
  }
};

export const otherEvent = {
  name: answerNames.otherEvent,
  heading: "What kind of event is this?",
  detail: "",
  answer: {
    title: "Event type",
    description: "Describe your event"
  },
  type: answerType.TEXT_INPUT,
  skipOption: false,
  options: ["Next"]
};

export const eventType = {
  name: answerNames.eventType,
  heading: "What type of event are you attending?",
  detail: "Pick one - you can add more events once we have this one lined up.",
  answers: [
    {
      photo: Wedding,
      title: "Wedding"
    },
    {
      photo: Gala,
      title: "Gala"
    },
    {
      photo: Vacation,
      title: "Vacation"
    },
    {
      photo: Cocktail,
      title: "Cocktail Party"
    },
    {
      photo: Other,
      title: "Let us know!"
    }
  ],
  followupQuestion: {
    Wedding: [weddingDetails],
    Gala: [],
    Vacation: [],
    "Cocktail Party": [],
    "Let us know!": [otherEvent]
  },
  type: answerType.PHOTO_DESCRIPTION,
  skipOption: false,
  options: ["Next"],
  singleSelect: true
};

export const ohAParty = {
  name: answerNames.ohAParty,
  heading: "Ooh! A Party!",
  detail: "",
  answers: [
    {
      title: "Check your inbox",
      description: ""
    }
  ],
  type: answerType.MESSAGE,
  skipOption: false,
  options: ["Next"]
};

export const ohAnotherParty = {
  name: answerNames.ohAParty,
  additionalBox: true,
  heading: "Ohh! Another Party!",
  detail: "",
  answers: [
    {
      title: "Check your inbox",
      description: ""
    }
  ],
  type: answerType.MESSAGE,
  skipOption: false,
  options: ["Next"]
};

export const evtPhotoUpload = {
  name: answerNames.photoUpload,
  heading: "Let's see what we're styling!",
  detail:
    "Please share a picture or screenshot of your outfit, shoes, and anything else you think would help.",
  options: ["Next"],
  answers: [
    {
      photoUrl: ""
    }
  ],
  type: answerType.PHOTO_UPLOAD,
  skipOption: "Skip"
};

export const anythingElse = {
  name: answerNames.anythingElse,
  heading: "Anything else we should know?",
  isSubmit: true,
  detail: "",
  answer: {
    title: "Details",
    description: "I'd like my stylist to know..."
  },
  type: answerType.TEXT_INPUT,
  skipOption: false,
  options: ["Next"],
  multiline: true
};

export const evtJewelryType = {
  name: answerNames.jewelryType,
  heading: "Which types of jewelry are you looking for?",
  detail: "Select as many as you like.",
  answers: [
    {
      photo: Images.necklace,
      photoAlt: Images.necklaceAlt,
      title: "Necklaces",
      description: ""
    },
    {
      photo: Images.earring,
      photoAlt: Images.earringsAlt,
      title: "Earrings",
      description: ""
    },
    {
      photo: Images.bracelet,
      photoAlt: Images.braceletAlt,
      title: "Bracelets",
      description: ""
    },
    {
      photo: Images.ring,
      photoAlt: Images.ringAlt,
      title: "Rings",
      description: ""
    }
  ],
  type: answerType.MULTIPLE_HORIZONTAL,
  skipOption: false,
  options: ["Next"]
};

export const evtBudget = {
  name: answerNames.budget,
  heading: "How much would you like to spend for this event?",
  detail: "",
  answers: [
    {
      title: "$",
      description: "$25 - $75"
    },
    {
      title: "$$",
      description: "$75 - $150"
    },
    {
      title: "$$$",
      description: "$150+"
    }
  ],
  type: answerType.SINGLE_SELECT,
  skipOption: false,
  options: ["Next"],
  fontStyling: {
    fontSize: "40px",
    marginTop: "20px"
  }
};
