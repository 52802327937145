import {
  budget,
  colorfulVsNeutral,
  email,
  frequency,
  gettingToKnowYou,
  goldVsSilver,
  jewelryType,
  layerItOn,
  name,
  photoUpload,
  stylePhotos,
  stylistDetails
} from "./UserQuestions";
import {
  anythingElse,
  eventDate,
  eventType,
  evtBudget,
  evtJewelryType,
  evtPhotoUpload,
  ohAParty
} from "./EventQuestions";
import { giftRecipient, letsGetGifting, sendECard } from "./GiftQuestions";

export const userQuestions = [
  stylePhotos,
  name,
  email,
  jewelryType,
  goldVsSilver,
  colorfulVsNeutral,
  budget,
  layerItOn,
  stylistDetails,
  photoUpload,
  frequency,
  gettingToKnowYou
];

export const eventQuestions = [
  ohAParty,
  eventDate,
  eventType,
  name,
  email,
  evtJewelryType,
  evtPhotoUpload,
  evtBudget,
  layerItOn,
  anythingElse
];

export const giftQuestions = [letsGetGifting, giftRecipient, sendECard];
