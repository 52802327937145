import React from "react";
import { isMobile } from "react-device-detect";
// Comnponents
import { ScrollPhoto } from "../atoms/PhotosMobile";

// Themes
import "./OrganismStyles.scss";

export function ScrollableColumn(props) {
  const {
    column,
    totalColumns,
    photoPicker,
    question,
    selected,
    selectedCategories,
    setSelected,
    setSelectedCategories
  } = props;
  const numberOfItems = parseInt(question.answers.length / totalColumns);
  const startLocation = parseInt(numberOfItems * column);
  const endLocation = parseInt(startLocation + numberOfItems);
  return (
    <div
      className="photo_grid_scroll_column"
      style={isMobile ? { marginRight: "0px", maxWidth: "auto" } : null}
    >
      {question.answers
        .slice(startLocation, endLocation)
        .map((answer, index) => {
          var url = answer.image;
          if (photoPicker !== null) {
            url = photoPicker[answer.category.toString()][answer.page];
          }
          const isSelected = selected[index + startLocation];
          const onSelect = () => {
            let newObj = selected;
            newObj[index + startLocation] = !selected[index + startLocation];
            setSelected({ ...newObj });
            var currentSelectedCategories = selectedCategories;
            if (isSelected) {
              currentSelectedCategories[answer.category.toString()] =
                currentSelectedCategories[answer.category.toString()] - 1;
            } else {
              currentSelectedCategories[answer.category.toString()] =
                currentSelectedCategories[answer.category.toString()] + 1;
            }
            setSelectedCategories(currentSelectedCategories);
          };
          return (
            <ScrollPhoto
              key={index + startLocation}
              select={onSelect}
              isSelected={isSelected}
              src={url}
            />
          );
        })}
    </div>
  );
}
