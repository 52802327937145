export default {
  "?": "UNKNOWN",
  "Cocktail Party": "PARTY",
  "Every Other Month": "60",
  "Every Three Months": "90",
  "I don't know": "I_DONT_KNOW",
  "Long Pendant": "PENDANT_LONG",
  "No, thank you": "NO_THANK_YOU",
  "Not right now": "NOT_RIGHT_NOW",
  "Once Monthly": "30",
  "Short Pendant": "PENDANT_SHORT",
  "Statement Ring": "STATEMENT",
  "Yes please!": "YES_PLEASE",
  "Yes!": "YES",
  $: "$",
  $$: "$$",
  $$$: "$$$",
  "$25 - $75": "$",
  "$75 - $150": "$$",
  "$150+": "$$$",
  10: "10",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  $75: "75",
  $100: "100",
  $150: "150",
  $200: "200",
  $250: "250",
  $300: "300",
  both: "BOTH",
  neither: "NEITHER",
  Bangle: "BANGLE",
  Bead: "BEAD",
  Bib: "BIB",
  Boho: "BOHO",
  Both: "BOTH",
  Bracelets: "BRACELETS",
  Choker: "CHOKER",
  Clasp: "CLASP",
  Classic: "CLASSIC",
  Colorful: "COLORFUL",
  Cuff: "CUFF",
  Dangle: "DANGLE",
  Delicate: "DELICATE",
  Earrings: "EARRINGS",
  Edgy: "EDGY",
  EVERY_OTHER_MONTH: "60",
  Gala: "GALA",
  Gold: "GOLD",
  Hair: "HAIR_ACCESSORY",
  Handbag: "HANDBAG",
  Home: "HOME_ACCESSORY",
  Hoop: "HOOP",
  Layered: "LAYERED",
  Necklaces: "NECKLACES",
  Neither: "NEITHER",
  Neutral: "NEUTRAL",
  ONCE_MONTHLY: "30",
  Other: "OTHER",
  "Let us know!": "OTHER",
  Preppy: "PREPPY",
  Rings: "RINGS",
  Scarf: "SCARF",
  Silver: "SILVER",
  Skipped: "SKIPPED",
  Statement: "STATEMENT",
  Strand: "STRAND",
  Stud: "STUD",
  Trendy: "TRENDY",
  Vacation: "VACATION",
  Wedding: "WEDDING"
};
