// Shared Constants
export const BANGLE = "Bangle";
export const BEAD = "Bead";
export const BIB = "Bib";
export const BOHO = "Boho";
export const BOTH = "Both";
export const BRACELETS = "Bracelets";
export const CHOKER = "Choker";
export const CLASP = "Clasp";
export const CLASSIC = "Classic";
export const COLORFUL = "Colorful";
export const CUFF = "Cuff";
export const DANGLE = "Dangle";
export const DELICATE = "Delicate";
export const EARRINGS = "Earrings";
export const EDGY = "Edgy";
export const EIGHT = "8";
export const FIVE = "5";
export const FOUR = "4";
export const GOLD = "Gold";
export const HAIR_ACCESSORY = "Hair";
export const HANDBAG = "Handbag";
export const HIGH = "$$$";
export const HOME_ACCESSORY = "Home";
export const HOOP = "Hoop";
export const I_DONT_KNOW = "I don't know";
export const LAYERED = "Layered";
export const LOW = "$";
export const MEDIUM = "$$";
export const NECKLACES = "Necklaces";
export const NEITHER = "Neither";
export const NEUTRAL = "Neutral";
export const NINE = "9";
export const NO_THANK_YOU = "No, thank you"; // NEW
export const NOT_RIGHT_NOW = "Not right now"; // NEW
export const PENDANT_LONG = "Long Pendant";
export const PENDANT_SHORT = "Short Pendant";
export const PREPPY = "Preppy";
export const RINGS = "Rings";
export const SCARF = "Scarf";
export const SEVEN = "7";
export const SILVER = "Silver";
export const SIX = "6";
export const SKIPPED = "Skipped";
export const STATEMENT = "Statement"; // NEW
export const STRAND = "Strand";
export const STUD = "Stud";
export const TEN = "10";
export const TRENDY = "Trendy";
export const UNKNOWN = "?";
export const YES = "Yes!"; // NEW
export const YES_PLEASE = "Yes please!"; // NEW

export const ONCE_MONTHLY = "Once Monthly"; // NEW
export const EVERY_OTHER_MONTH = "Every Other Month"; // NEW
export const EVERY_THREE_MONTHS = "Every Three Months"; // NEW
