export const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
};

export const validateName = name => {
  // eslint-disable-next-line no-useless-escape
  const re = /^[A-Za-z\-\s]+$/;
  if (name === " ") return false;
  else return re.test(name);
};

export const validateSocial = social => {
  return social !== "";
};
