export const PathNames = {
  EVENT: "EVENT",
  USER: "USER",
  GIFT: "GIFT"
};

export const getPathName = windowPath => {
  switch (windowPath) {
    case "/event":
      return PathNames.EVENT;
    case "/gift":
      return PathNames.GIFT;
    default:
      return PathNames.USER;
  }
};
