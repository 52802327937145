import React, { useState } from "react";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
// Components
import Question from "./Question";
import QuestionMobile from "./Question_Mobile";
import { ErrorModal, Modal } from "../organisms/Modal";
// Constants
import { userQuestions } from "../../constants/Questions";
import { SurveyType } from "../../shared/constants/survey";
import { validateEmail } from "../../utils/index";
// Redux
import {
  createOrGetUser,
  getSurveyResponse,
  resetError,
  resetRedirect,
  signIn,
  signOut,
  signUp,
  submitUserResponse,
  updateAnswer
} from "../../redux/actions/User";
import { getPhotoPickerPhotos, uploadPhotos } from "../../redux/actions/Photos";
import { trackPageView } from "../../utils/Mixpanel";

function UserSurvey(props) {
  const {
    attemptCreateOrGetUser,
    attemptGetUserSurvey,
    attemptResetRedirect,
    attemptResetError,
    attemptSignIn,
    attemptSignOut,
    attemptSignUp,
    attemptSubmitSurvey,
    credentials,
    errorMessage,
    handleQuizChange,
    hasCompleted,
    isLoading,
    isSignedIn,
    redirect,
    startingQuestion,
    uploadedPhotos,
    userAnswers
  } = props;
  const [isLogin, setIsLogin] = useState(true);
  const toggleLogin = () => setIsLogin(!isLogin);
  const [showModal, setShowModal] = useState(false);
  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const [currentQuestion, setCurrentQuestion] = useState(startingQuestion);
  const [questions, setQuestions] = useState(userQuestions);
  const question = questions[currentQuestion];

  const back =
    currentQuestion > 0 ? () => setCurrentQuestion(currentQuestion - 1) : null;

  const next =
    currentQuestion < questions.length - 1
      ? addToAmount => {
          if (currentQuestion === 0 && isSignedIn) {
            // check if completed survey previously
            attemptGetUserSurvey();
          }
          if (question.name === "email") {
            if (validateEmail(userAnswers.email)) {
              attemptCreateOrGetUser();
            } else {
              attemptCreateOrGetUser();
              addToAmount = currentQuestion;
            }
          }
          trackPageView(SurveyType.USER, {
            Viewed_Question: question.name,
            UserSurveyAnswers: userAnswers
          });
          setCurrentQuestion(addToAmount);
        }
      : null;
  const handleNext = selectedAnswer => {
    let nextAmount = 1;
    // for removing questions if they've completed the survey previously

    const previousQuestionArr = [...questions];
    if (question.skipQuestion) {
      if (selectedAnswer) {
        props.attemptUpdateAnswer(question, selectedAnswer);
      } else {
        const newQuestions = [
          ...userQuestions.slice(0, currentQuestion + 1),
          question.skipQuestion,
          ...userQuestions.slice(currentQuestion + 1)
        ];
        setQuestions(newQuestions);
        props.attemptUpdateAnswer(question, "");
      }
    } else if (
      question.followupQuestion &&
      question.followupQuestion[selectedAnswer[0]] &&
      question.followupQuestion[selectedAnswer[0]].length > 0
    ) {
      if (selectedAnswer) {
        const keysToUse = Object.keys(question.followupQuestion)
          .filter(arrName => selectedAnswer.includes(arrName))
          .map(key => question.followupQuestion[key])
          .flat(1);
        const previousQuestions = previousQuestionArr.slice(
          0,
          currentQuestion + 1
        );
        const remainingQuestions = hasCompleted
          ? userQuestions
              .filter(question => question.name !== "frequency")
              .slice(previousQuestions.length)
          : userQuestions.slice(previousQuestions.length - 1);
        const newQuestions = [
          ...previousQuestions,
          ...keysToUse,
          ...remainingQuestions.filter(
            question => question.name !== "jewelryType"
          )
        ];
        setQuestions(newQuestions);
        props.attemptUpdateAnswer(question, selectedAnswer);
        nextAmount = previousQuestions.length - currentQuestion;
      } else {
        props.attemptUpdateAnswer(question, "");
      }
    } else {
      if (selectedAnswer) {
        props.attemptUpdateAnswer(question, selectedAnswer);
      } else {
        props.attemptUpdateAnswer(question, "");
      }
    }
    next(currentQuestion + nextAmount);
    nextAmount = 1;
  };

  const submit = () => {
    attemptSubmitSurvey();
  };

  if (redirect && !isLoading) {
    const tempRedirect = redirect;
    attemptResetRedirect();
    window.location = tempRedirect;
  }

  if (question.skipIfSignedIn && isSignedIn) {
    questions.splice(currentQuestion, 1);
    setCurrentQuestion(currentQuestion);
  }

  const isShowingModal = showModal
    ? e => {
        if (e.target.closest(".modal")) {
        } else {
          setShowModal(false);
        }
      }
    : null;
  let userName = "";
  if (props.userAnswers) {
    if (props.userAnswers.name) {
      userName = props.userAnswers.name;
    }
  }

  const handleError = () => {
    if (errorMessage) {
      attemptResetError();
    }
  };

  return isMobile ? (
    <QuestionMobile
      back={back}
      credentials={credentials}
      handleQuizChange={handleQuizChange}
      isShowingModal={isShowingModal}
      isSignedIn={isSignedIn}
      next={handleNext}
      previousAnswers={userAnswers}
      question={question}
      signOut={attemptSignOut}
      skipOption={question.skipOption}
      submit={submit}
      surveyType="User"
      toggleShowModal={toggleShowModal}
      uploadedPhotos={uploadedPhotos}
      uploadPhotos={props.attemptUploadPhotos}
      userName={userName}
      errorModal={
        <ErrorModal
          showErrorModal={errorMessage !== "" && question.name === "email"}
          errorMessage={errorMessage}
          errorAction={handleError}
        />
      }
      modal={
        <Modal
          credentials={credentials}
          show={showModal}
          toggleLogin={toggleLogin}
          createOrGetUser={attemptCreateOrGetUser}
          signIn={attemptSignIn}
          signUp={attemptSignUp}
          isLogin={isLogin}
          toggleShowModal={toggleShowModal}
        />
      }
    />
  ) : (
    <Question
      back={back}
      credentials={credentials}
      handleQuizChange={handleQuizChange}
      isShowingModal={isShowingModal}
      isSignedIn={isSignedIn}
      next={handleNext}
      previousAnswers={userAnswers}
      question={question}
      signOut={attemptSignOut}
      skipOption={question.skipOption}
      submit={submit}
      surveyType="User"
      toggleShowModal={toggleShowModal}
      uploadedPhotos={uploadedPhotos}
      uploadPhotos={props.attemptUploadPhotos}
      userName={userName}
      errorModal={
        <ErrorModal
          showErrorModal={errorMessage !== "" && question.name === "email"}
          errorMessage={errorMessage}
          errorAction={handleError}
        />
      }
      modal={
        <Modal
          show={showModal}
          toggleLogin={toggleLogin}
          createOrGetUser={attemptCreateOrGetUser}
          signIn={attemptSignIn}
          signUp={attemptSignUp}
          credentials={credentials}
          isLogin={isLogin}
          toggleShowModal={toggleShowModal}
        />
      }
    />
  );
}

const mapStateToProps = state => ({
  credentials: state.user.credentials,
  isLoading: state.user.isLoading,
  isSignedIn: state.user.user,
  redirect: state.user.redirect,
  uploadedPhotos: state.photos.uploadedPhotos,
  userAnswers: state.user.answers,
  hasCompleted: state.user.hasCompleted,
  userTags: state.user.tags,
  errorMessage: state.user.error
});

const mapDispatchToProps = dispatch => {
  return {
    attemptUpdateAnswer: (question, answer) =>
      dispatch(updateAnswer(question, answer)),
    attemptGetPhotoPickerPhotos: () => dispatch(getPhotoPickerPhotos()),
    attemptUploadPhotos: fileList =>
      dispatch(uploadPhotos(SurveyType.USER, fileList)),
    attemptSignIn: formDetails => dispatch(signIn(formDetails)),
    attemptSignUp: formDetails => dispatch(signUp(formDetails)),
    attemptCreateOrGetUser: () => dispatch(createOrGetUser(SurveyType.USER)),
    attemptSubmitSurvey: () => dispatch(submitUserResponse()),
    attemptResetRedirect: () => dispatch(resetRedirect()),
    attemptSignOut: () => dispatch(signOut()),
    attemptGetUserSurvey: () => dispatch(getSurveyResponse()),
    attemptResetError: () => dispatch(resetError())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSurvey);
