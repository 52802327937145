import {
  CREATE_OR_GET,
  GET_USER_SURVEY,
  HIDE_ERROR,
  HIDE_LOADING,
  LOGOUT,
  RESET_REDIRECT,
  SET_USER_ANSWERS,
  SHOW_ERROR,
  SHOW_LOADING_USER,
  SIGN_IN,
  SUBMIT_USER_SURVEY
} from "../actions/Types";
import Immutable from "seamless-immutable";
import SurveyType from "../../shared/constants/survey";

import { setOrGetUserAnalytics } from "../../utils/Mixpanel";

// initial state
const initialState = Immutable({
  user: null,
  survey: null,
  redirect: null,
  surveyTpe: SurveyType.USER,
  hasCompleted: false,
  credentials: {
    user: null,
    name: null,
    firstName: null,
    lastName: null,
    email: null,
    password: null
  },
  isSignedIn: false,
  answers: {
    stylePhotos: null,
    styleFeel: null,
    name: null,
    email: null,
    jewelryType: null,
    layeredVsStrand: null,
    chokerVsBib: null,
    shortVsLong: null,
    delicateVsStatement: null,
    studVsDangle: null,
    hoopVsStatement: null,
    beadVsBangle: null,
    claspVsCuff: null,
    delicateVsStatementRing: null,
    goldVsSilver: null,
    colorfulVsNeutral: null,
    ringSize: null,
    budget: null,
    layerItOn: null,
    stylistDetails: null,
    photoUpload: null,
    pinterest: null,
    instagram: null,
    stylingVideo: null,
    frequency: null,
    bigEvent: null,
    gettingToKnowYou: null
  },
  tags: {},
  isLoading: false,
  error: ""
});

// reducers

const showError = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    error: action.error,
    isLoading: false
  });
};

const setUserAnswers = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    answers: {
      ...state.answers,
      error: "",
      [action.question.name]: action.answer
    },
    isLoading: false
  });
};
const createOrGetUser = (state, action) => {
  const { name, user, survey, email } = action;

  // setOrGetUserAnalytics({ name, user, survey, email });

  return Immutable.merge(state, {
    ...state,
    user: user ? user : state.user,
    survey: survey ? survey : state.survey,
    credentials: {
      ...state.credentials,
      user: user ? user : state.credentials.user,
      name: name ? name : state.credentials.name,
      email: email ? email : state.credentials.email,
      firstName: name ? name : state.credentials.name
    },
    answers: {
      ...state.answers,
      name: name ? name : state.answers.name,
      email: email ? email : state.answers.email
    },
    isSignedIn: true,
    isLoading: false
  });
};

const login = (state, action) => {
  const { name, user, survey, email } = action;
  setOrGetUserAnalytics({ name, user, survey, email });

  return Immutable.merge(state, {
    ...state,
    user: user ? user : state.user,
    survey: survey ? survey : state.survey,
    credentials: {
      ...state.credentials,
      user: user ? user : state.credentials.user,
      name: name ? name : state.credentials.name,
      email: email ? email : state.credentials.email,
      firstName: name ? name : state.credentials.name
    },
    answers: {
      ...state.answers,
      name: name ? name : state.answers.name,
      email: email ? email : state.answers.email
    },
    isSignedIn: true,
    isLoading: false
  });
};

const submitUserSurvey = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    redirect: action.redirect,
    isLoading: false
  });
};

const resetRedirect = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    redirect: null,
    isLoading: false
  });
};

const setIsLoading = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    isLoading: true
  });
};

const hideLoading = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    isLoading: false
  });
};

const getUserSurvey = (state, action) => {
  const { data } = action;
  return Immutable.merge(state, {
    ...state,
    hasCompleted: data.hasCompleted,
    answers: {
      ...state.answers,
      frequency: data.hasCompleted ? data.surveyData.frequency : null
    },
    isLoading: false
  });
};

const resetError = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    error: ""
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OR_GET:
      return createOrGetUser(state, action);
    case SET_USER_ANSWERS: {
      return setUserAnswers(state, action);
    }
    case SHOW_LOADING_USER: {
      return setIsLoading(state, action);
    }
    case SIGN_IN: {
      return login(state, action);
    }
    case SUBMIT_USER_SURVEY:
      return submitUserSurvey(state, action);
    case SHOW_ERROR:
      return showError(state, action);
    case RESET_REDIRECT:
      return resetRedirect(state, action);
    case LOGOUT:
      return initialState;
    case GET_USER_SURVEY:
      return getUserSurvey(state, action);
    case HIDE_ERROR:
      return resetError(state, action);
    case HIDE_LOADING:
      return hideLoading(state, action);
    default:
      return state;
  }
};

export default reducer;
