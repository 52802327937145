import React, { useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import api from "./api";
import mixpanel from "mixpanel-browser";

// Comnponents
import { SurveyHeader } from "./components/molecules/Header";
import UserSurvey from "./components/templates/UserSurvey";
import EventSurvey from "./components/templates/EventSurvey";
import GiftSurvey from "./components/templates/GiftSurvey";

// Config
import { configureStore } from "./redux";
import FirebaseConfig from "./configs/firebase/config";
import { PathNames, getPathName } from "./constants/PathNames";
// Firebase
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
// Theme
import "./App.css";

const { store, persistor } = configureStore();
api.init();

function App() {
  if (!firebase.apps.length) {
    firebase.initializeApp(FirebaseConfig);
  }

  mixpanel.init("0f9644e14057e193a1193093b5084324");

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const navName = getPathName(window.location.pathname);
  const [currentQuiz, setQuiz] = useState(navName);

  const setCurrentQuiz = newQuiz => {
    setQuiz(window.pathname);
    switch (window.pathname) {
      case PathNames.EVENT:
        setCurrentQuestion(0);
        break;
      case PathNames.GIFT:
        setCurrentQuestion(0);
        break;
      default:
        setCurrentQuestion(0);
        break;
    }
  };

  const handleQuizChange = newQuiz => {
    setCurrentQuestion(0);
    setCurrentQuiz(newQuiz);
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="survey_main_container">
          <SurveyHeader currentQuiz={currentQuiz} setQuiz={setCurrentQuiz} />
          <CurrentSurvey
            handleQuizChange={handleQuizChange}
            currentQuiz={currentQuiz}
            startingQuestion={currentQuestion}
          />
        </div>
      </PersistGate>
    </Provider>
  );
}

function CurrentSurvey(props) {
  const { currentQuiz, startingQuestion, handleQuizChange } = props;

  switch (currentQuiz) {
    case PathNames.EVENT:
      return (
        <EventSurvey
          startingQuestion={startingQuestion}
          handleQuizChange={handleQuizChange}
        />
      );
    case PathNames.GIFT:
      return (
        <GiftSurvey
          startingQuestion={startingQuestion}
          handleQuizChange={handleQuizChange}
        />
      );
    default:
      return (
        <UserSurvey
          startingQuestion={startingQuestion}
          handleQuizChange={handleQuizChange}
        />
      );
  }
}

export default App;
