import React, { useState } from "react";
import styled from "styled-components";
// Components
import { isMobile } from "react-device-detect";
import { PngImage } from "./Images";
// Constants
import Images from "../../constants/Images";
// Theme
import "./AtomStyles.scss";

const mobileWidth = "120px";

const StyledIconContainer = styled.div`
  height: ${() => (isMobile ? mobileWidth : "150px")};
  width: ${() => (isMobile ? mobileWidth : "150px")};
  display: flex;
  cursor: default;
  align-items: center;
  align-content: center;
  text-align: center;
  background-color: ${props => (props.isSelected ? "#336472" : "#FDE6E1")};
  border-radius: ${props => (props.rounded ? "16px" : "0px")};
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 10px;
`;

const StyledImageContainer = styled.div`
  height: ${() => (isMobile ? mobileWidth : "150px")};
  width: ${() => (isMobile ? mobileWidth : "150px")};
  cursor: default;
  align-items: center;
  background-color: ${props => (props.isSelected ? "#336472" : "#FDE6E1")};
  border-radius: ${props => (props.rounded ? "16px" : "0px")};
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px;
`;

const StyledText = styled.div`
  color: ${props => (props.isSelected ? "white" : "#3D4543")};
  align-self: center;
  align-items: center;
  text-align: center;
  font-size: ${() => (isMobile ? "16px" : "16px")};
`;

const StyledTitle = styled.h1`
  color: ${props => (props.isSelected ? "white" : "#3D4543")};
  font-size: ${() => (isMobile ? "30px" : "40px")};
  margin: 10px 0 0 0;
`;
const StyledDescription = styled.h2`
  color: ${props => (props.isSelected ? "white" : "#3D4543")};
  font-size: ${() => (isMobile ? "18px" : "18px")};
  text-align: center;
  align-self: center;
  margin-top: 0px;
`;

export function SelectableIcon({
  src,
  select,
  description,
  isSelected,
  title,
  fontStyling,
  fontFamily
}) {
  let customFontStyle = {
    fontFamily: "Sofia Pro Regular",
    fontSize: "30px",
    ...fontStyling
  };
  if (fontFamily) {
    customFontStyle = {
      ...customFontStyle,
      fontFamily: fontFamily
    };
  }
  return src ? (
    <StyledImageContainer onClick={select} isSelected={isSelected} rounded>
      {isSelected ? (
        <PngImage absolute size={21} src={Images.checkmarkPng} />
      ) : null}
      <PngImage size={isMobile ? 50 : 100} src={src} rounded />
      <StyledText isSelected={isSelected}>{title}</StyledText>
    </StyledImageContainer>
  ) : (
    <StyledIconContainer onClick={select} isSelected={isSelected} rounded>
      {isSelected ? (
        <PngImage absolute size={21} src={Images.checkmarkPng} />
      ) : null}
      <StyledTitle isSelected={isSelected} style={customFontStyle}>
        {title}
      </StyledTitle>
      {description ? (
        <StyledDescription isSelected={isSelected}>
          {description}
        </StyledDescription>
      ) : null}
    </StyledIconContainer>
  );
}

export function GridPhoto({ src, select, isSelected }) {
  const containerStlye = {
    position: "sticky",
    width: "220px",
    height: "250px",
    borderWidth: "0px",
    borderColor: "white",
    zIndex: 100,
    borderRadius: "16px",
    marginBottom: "10px",
    backgroundColor: "#336472"
  };

  const altString = "";
  return (
    <div onClick={select} style={containerStlye}>
      {isSelected ? (
        <PngImage absolute size={21} src={Images.checkmarkPng} />
      ) : null}
      <img
        alt={altString}
        className={isSelected ? "simple_photo_selected" : "simple_photo"}
        src={src}
      />
    </div>
  );
}

export function GridPhotoThisThat({ src, select, isSelected }) {
  const altString = "";
  return (
    <img
      alt={altString}
      onClick={select}
      className={isSelected ? "this_that_photo_selected" : "this_that_photo"}
      src={src}
    />
  );
}

export function ScrollPhoto({ src, select, isSelected }) {
  const [aspectRatio, setAspectRatio] = useState(0);
  const [showTemp, toggleTemp] = useState(true);
  const onImgLoad = ({ target: img }) => {
    const ratio = img.naturalWidth / img.naturalHeight;
    toggleTemp(false);
    setAspectRatio(ratio);
  };
  const mobileWidth = "140px";
  const photoStyle = {
    height: aspectRatio === 1 ? "125px" : aspectRatio > 1 ? "100px" : "200px",
    width: isMobile ? mobileWidth : "125px",
    borderRadius: "16px",
    opacity: isSelected ? 0.4 : 1
  };

  const containerStlye = {
    position: "sticky",
    margin: "10px",
    borderWidth: "0px",
    borderColor: "white",
    height: aspectRatio === 1 ? "125px" : aspectRatio > 1 ? "100px" : "200px",
    width: isMobile ? mobileWidth : "125px",
    zIndex: 100,
    borderRadius: "16px",
    backgroundColor: showTemp ? "#dedede" : "#336472"
  };

  const altString = "";
  return (
    <div
      onClick={select}
      className={showTemp ? "icon" : ""}
      style={containerStlye}
    >
      {isSelected ? (
        <PngImage absolute size={21} src={Images.checkmarkPng} />
      ) : null}
      <img alt={altString} onLoad={onImgLoad} style={photoStyle} src={src} />
    </div>
  );
}

export function GridText() {
  return <div className="simple_photo">Example</div>;
}
