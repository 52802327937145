import React from "react";
// Components
import { ReactSVG } from "react-svg";

// Theme
import "./AtomStyles.scss";

export function SvgImage({ absolute, rounded, src, size }) {
  const borderRadius = rounded ? 16 : 0;
  const defaultStyle = {
    height: size,
    width: size,
    borderRadius: borderRadius,
    marginBottom: "10px" // change back if text only looks weird
  };
  const absoluteStyle = {
    display: "flex",
    height: size,
    width: size,
    borderRadius: size,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    right: size / 2,
    top: size / 2,
    backgroundColor: "white",
    padding: size / 3
  };

  const altString = "";
  return (
    <ReactSVG
      alt={altString}
      style={absolute ? absoluteStyle : defaultStyle}
      src={src}
    />
  );
}

export function PngImage({ absolute, rounded, src, size }) {
  const borderRadius = rounded ? 16 : 0;
  const defaultStyle = {
    height: size,
    width: size,
    borderRadius: borderRadius
  };
  const absoluteStyle = {
    height: size,
    width: size,
    borderRadius: size,
    position: "absolute",
    right: size / 2,
    top: size / 2,
    backgroundColor: "white",
    padding: size / 3
  };

  const altString = "";
  return (
    <img
      alt={altString}
      style={absolute ? absoluteStyle : defaultStyle}
      src={src}
    />
  );
}
