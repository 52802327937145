import { isMobile } from "react-device-detect";

import Trendy from "../images/Style Picker/Trendy-StylePicker.jpg";
import Classic from "../images/Style Picker/Classic-StylePicker.jpg";
import Edgy from "../images/Style Picker/Edgy-StylePicker.jpg";
import Preppy from "../images/Style Picker/Preppy-StylePicker.jpg";
import Boho from "../images/Style Picker/Boho-StylePicker.jpg";
import NotSure from "../images/PngIcons/NotSure.png";

// SVGs
import Images from "../constants/Images";

import answerType from "./AnswerTypes";
import answerNames from "./AnswerNames";
import { categories } from "./UserQuestions";
import { validateEmail, validateName } from "../utils/index";

// Shared Constants
import * as userSurveyStrings from "./UserSurveyStrings";

const today = new Date();

export const giftName = {
  name: answerNames.name,
  heading: "By the way, what's your name?",
  detail:
    "Don’t worry, we’ll get the name of who you’re gifting this to later.",
  answer: {
    title: "First Name",
    description: "type your name"
  },
  type: answerType.TEXT_INPUT,
  skipOption: false,
  options: ["Next"],
  validation: validateName,
  skipIfSignedIn: true,
  hideProgress: true
};

export const giftEmail = {
  name: answerNames.email,
  heading: "Brilliant! What's your email address?",
  detail: "",
  answer: {
    title: "e-mail",
    description: "type your e-mail"
  },
  type: answerType.TEXT_INPUT,
  skipOption: false,
  options: ["Next"],
  validation: validateEmail,
  skipIfSignedIn: true,
  hideProgress: true
};

export const letsGetGifting = {
  name: answerNames.letsGetGifting,
  heading: "Let's get giving!",
  detail: "",
  answers: [
    {
      title: "",
      description: ""
    }
  ],
  type: answerType.MESSAGE,
  skipOption: false,
  options: ["Next"],
  hideProgress: true
};

export const giftDeliveryDate = {
  name: answerNames.giftDeliveryDate,
  heading: "Request a delivery date for your gift box.",
  detail: "At least two weeks out.",
  answer: {
    title: "Delivery Date",
    description: "Delivery Date"
  },
  type: answerType.DATE,
  skipOption: false,
  hideProgress: true,
  options: ["Next"],
  dateRequirement: new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 1
  )
};

export const giftWhen = {
  name: answerNames.giftWhen,
  heading: "When would you like the gift to be delivered?",
  detail: "We need at least one week to select your pieces.",
  answer: {
    title: "Event Date",
    description: ""
  },
  type: answerType.DATE,
  skipOption: false,
  options: ["Next"],
  hideProgress: true,
  dateRequirement: new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 7
  )
};

export const giftRecipient = {
  name: answerNames.giftRecipient,
  heading: "Who are you gifting?",
  detail: "",
  answers: [
    {
      title: "Wife"
    },
    {
      title: "Girlfriend"
    },
    {
      title: "Friend"
    },
    {
      title: "Mom"
    },
    {
      title: "Sister"
    },
    {
      title: "Other"
    }
  ],
  type: answerType.SINGLE_SELECT,
  skipOption: false,
  options: ["Next"],
  // isLogin: true,
  hideProgress: true,
  fontStyling: isMobile
    ? { fontSize: "18px", lineHeight: "26px" }
    : { fontSize: "18px", lineHeight: "26px" }
};

export const giftDetails = {
  name: answerNames.giftDetails,
  heading: "Is there anything else you'd like us to know about your giftee?",
  detail: "Giftee name, occasion, and any additional details.",
  answer: {
    title: "Additional details",
    description: "Additional details"
  },
  type: answerType.TEXT_INPUT,
  skipOption: "Skip",
  options: ["Next"],
  hideProgress: true,
  multiline: true
};

export const requestedDeliveryDate = {
  name: answerNames.requestedDeliveryDate,
  heading: "Perfect. Tell us about the giftee",
  detail: "Delivery Date.",
  answer: {
    title: "Delivery Date",
    description: "Delivery Date"
  },
  type: answerType.DATE,
  skipOption: false,
  options: ["Next"],
  hideProgress: true,
  dateRequirement: new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  )
};

// remove
// export const emailOrPrint = {
//   name: answerNames.emailOrPrint,
//   heading:
//     "Shall we send an email straight to the giftee or would you like to print this out and give it yourself?",
//   detail: "",
//   answers: [
//     {
//       title: "Email Giftee"
//     },
//     {
//       title: "I will Print"
//     }
//   ],
//   followupQuestion: {
//     "Email Giftee": [giftEmail, giftDeliveryDate],
//     "I will Print": [giftName, giftDeliveryDate]
//   },
//   type: answerType.DESCRIPTION,
//   skipOption: false,
//   options: ["Next", "Both", "Neither"],
//   hideProgress: true
// };

export const perfectGift = {
  name: answerNames.perfectGift,
  heading: `The Perfect Gift`,
  detail: `A 2-3 piece "gift" box starting at $75.`,
  answers: [
    {
      title: "",
      description: ""
    }
  ],
  type: answerType.MESSAGE,
  autoNextTime: 3,
  skipOption: false,
  options: ["Next"],
  hideProgress: true
};

export const giftStyleFeel = {
  name: answerNames.giftStyleFeel,
  heading: "Tell us about your giftee's style...",
  detail: "More than one is okay, there are no right or wrong answers.",
  answers: [
    {
      photo: Classic,
      category: categories.classic,
      title: "Classic",
      description: "Timeless, quality, iconic, simple"
    },
    {
      photo: Edgy,
      category: categories.edgy,
      title: "Edgy",
      description: "Bold, eclectic, unconventional, confident"
    },
    {
      photo: Boho,
      category: categories.boho,
      title: "Boho",
      description: "Natural materials, beads, unique, carefree"
    },
    {
      photo: Preppy,
      category: categories.preppy,
      title: "Preppy",
      description: "Pastels, florals, tailored, refined"
    },
    {
      photo: Trendy,
      category: categories.trendy,
      title: "Trendy",
      description: "Spontaneous, colorful, easy, fresh"
    },
    {
      photo: NotSure,
      category: categories.trendy,
      title: userSurveyStrings.I_DONT_KNOW,
      description: "Let the Stylist pick what’s best for them"
    }
  ],
  type: answerType.PHOTO_DESCRIPTION,
  skipOption: false,
  options: ["Next"],
  hideProgress: true
};

export const giftJewelryType = {
  name: answerNames.giftJewelryType,
  heading: "Which types of jewelry do they like?",
  detail: "Select as many as you like.",
  answers: [
    {
      photo: Images.necklace,
      photoAlt: Images.necklaceAlt,
      title: userSurveyStrings.NECKLACES,
      description: userSurveyStrings.NECKLACES
    },
    {
      photo: Images.bracelet,
      photoAlt: Images.braceletAlt,
      title: userSurveyStrings.BRACELETS,
      description: userSurveyStrings.BRACELETS
    },
    {
      photo: Images.earring,
      photoAlt: Images.earringsAlt,
      title: userSurveyStrings.EARRINGS,
      description: userSurveyStrings.EARRINGS
    }
  ],
  type: answerType.MULTIPLE_HORIZONTAL,
  skipOption: false,
  options: ["Next"],
  hideProgress: true
};

export const giftOrGiftee = {
  // TODO take straight to shopify
  name: answerNames.giftOrGiftee,
  heading: "Shall we send the box to you?",
  detail: "",
  answers: [
    {
      title: "Send to me"
    },
    {
      title: "Send to them"
    }
  ],
  type: answerType.DESCRIPTION,
  skipOption: false,
  options: ["Next", "Both", "Neither"],
  hideProgress: true,
  isSubmit: true
};

export const giftBudget = {
  name: answerNames.budget,
  heading: "How much would you like to spend on this gift box?",
  detail: "Any budget you pick will include 2-3 pieces per box.",
  answers: [
    {
      title: "$75",
      description: ""
    },
    {
      title: "$100",
      description: ""
    },
    {
      title: "$150",
      description: ""
    },
    {
      title: "$200",
      description: ""
    },
    {
      title: "$250",
      description: ""
    },
    {
      title: "$300",
      description: ""
    }
  ],
  type: answerType.SINGLE_SELECT,
  skipOption: false,
  options: ["Next"],
  hideProgress: true,
  fontFamily: "Sofia Pro Regular"
};

export const giftSubmit = {
  name: answerNames.letsGetGifting,
  heading: "Let's get you set up.",
  detail: "Be sure to enter the correct delivery address when you check out.",
  answers: [
    {
      title: "",
      description: ""
    }
  ],
  type: answerType.SUBMIT,
  skipOption: false,
  options: ["Next"],
  hideProgress: true,
  isSubmit: true
};

export const sendECard = {
  name: answerNames.sendECard,
  heading: "Would you like to send an e-card or a gift box?",
  detail: "",
  answers: [
    {
      photo: "https://via.placeholder.com/400x300",
      title: "E-Card"
    },
    {
      photo: "https://via.placeholder.com/400x300",
      title: "Gift Box"
    }
  ],
  redirectUrl:
    "https://shop.layerist.co/products/rise-ai-giftcard?_pos=1&_sid=87ac3a09c&_ss=r",
  redirect: answer =>
    answer === "E-Card"
      ? "https://shop.layerist.co/products/rise-ai-giftcard?_pos=1&_sid=87ac3a09c&_ss=r"
      : null,
  followupQuestion: {
    "E-Card": [], //Go straight to https://shop.layerist.co/products/rise-ai-giftcard?_pos=1&_sid=87ac3a09c&_ss=r
    "Gift Box": [
      perfectGift,
      giftEmail,
      giftName,
      giftWhen,
      giftStyleFeel,
      giftJewelryType,
      giftBudget,
      giftDetails,
      giftSubmit
    ]
  },
  type: answerType.DESCRIPTION,
  skipOption: false,
  options: ["Next", "Both", "Neither"],
  hideProgress: true
};
