import React, { useState } from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
// Comnponents
import { SurveyFooter } from "../molecules/Footer";
import { SurveyQuestion } from "../molecules/Question";
import "react-datepicker/dist/react-datepicker.css";
import { SelectableIcon } from "../atoms/Photos";
// Themes
import "./PageStyles.scss";

const HorizontalContainer = styled.div`
  display: flex;
  justify-content: center;
  width: ${props => props.width};
  margin-top: ${() => (isMobile ? "0px" : "5vh")};
  flex-direction: ${props => props.flexDirection};
  align-self: ${() => (isMobile ? "center" : "center")};
`;

export function HorizontalPhotoWithDescription(props) {
  const { back, next, skipOption, question, previousAnswers } = props;
  const numberOfAnswers = question.answers.length;
  let previousAnswer = [];
  if (previousAnswers) {
    previousAnswer =
      previousAnswers[question.name] !== null
        ? previousAnswers[question.name]
        : [];
  }
  let obj = question.answers.map(answer => {
    return previousAnswer.includes(answer.title);
  });
  const [selected, setSelected] = useState(obj);
  const [selectedTitles, setSelectedTitle] = useState([...previousAnswer]);
  const handleNext = () => {
    next(selectedTitles);
    setSelectedTitle([]);
    setSelected([]);
  };
  const handleSkip = () => {
    next([]);
    setSelectedTitle([]);
    setSelected([]);
  };
  let columnWidth =
    numberOfAnswers === 3 && isMobile
      ? "30vw"
      : isMobile
      ? "10vw"
      : numberOfAnswers === 4
      ? "420px"
      : numberOfAnswers === 6
      ? "50vw"
      : "60vw";

  const canProceed = selectedTitles.length > 0 || skipOption;

  const newStyle = {
    display: "flex",
    height: isMobile ? "auto" : "100%",
    paddingBottom: isMobile ? "80px" : "auto",
    width: isMobile ? "auto" : columnWidth,
    minHeight: isMobile ? "50vh" : "auto",
    flexDirection: isMobile ? "row" : "row",
    alignSelf: "center",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
    marginBottom: "5vh",
    overflowY: "auto",
    alignItems: "center"
  };

  return (
    <div
      className="answer_container"
      style={
        isMobile
          ? {
              width: "90vw",
              flexDirection: "column",
              alignSelf: "center",
              paddingRight: "1.5%"
            }
          : { width: "100%", height: "70vh" }
      }
    >
      <SurveyQuestion question={question} />
      <HorizontalContainer
        width={isMobile ? (numberOfAnswers === 3 ? "80vw" : "90vw") : "60vw"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <div style={newStyle}>
          {question.answers.map((answer, index) => {
            const isSelected = selectedTitles.indexOf(answer.title) !== -1;
            const onSelect = () => {
              let newObj = selected;
              if (selectedTitles.includes(answer.title)) {
                var index = selectedTitles.indexOf(answer.title);
                selectedTitles.splice(index, 1);
              } else {
                selectedTitles.push(answer.title);
              }
              let selectedTitleUpdate = selectedTitles;
              newObj[index] = !selected[index];
              setSelected({ ...newObj });
              setSelectedTitle(selectedTitleUpdate);
            };
            return (
              <SelectableIcon
                key={index}
                title={answer.title}
                isSelected={isSelected}
                select={onSelect}
                altSrc={answer.photoAlt}
                src={isSelected ? answer.photoAlt : answer.photo}
                fontStyling={question.fontStyling}
                fontFamily={question.fontFamily}
              />
            );
          })}
        </div>
      </HorizontalContainer>
      <SurveyFooter
        back={back}
        skip={handleSkip}
        skipOption={question.skipOption}
        next={handleNext}
        canProceed={canProceed}
      />
    </div>
  );
}

export function HorizontalSingleChoice(props) {
  const { back, next, skipOption, question } = props;
  const numberOfAnswers = question.answers.length;

  let columnWidth =
    numberOfAnswers === 3 && isMobile
      ? "30vw"
      : isMobile
      ? "10vw"
      : numberOfAnswers === 4
      ? "325px"
      : numberOfAnswers === 6
      ? "39vw"
      : "60vw";

  const newStyle = {
    display: "flex",
    height: isMobile ? "auto" : "auto",
    paddingBottom: isMobile ? "80px" : null,
    width: isMobile ? "auto" : columnWidth,
    minHeight: isMobile ? "50vh" : "auto",
    flexDirection: isMobile ? "row" : "row",
    alignSelf: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    marginBottom: "5vh",
    overflowY: "auto",
    alignContent: "center",
    alignItems: "center",
    marginTop: isMobile ? null : "-10vh"
  };

  return (
    <div
      className="answer_container"
      style={
        isMobile
          ? { width: "auto", flexDirection: "column" }
          : { width: "100%", height: "79vh" }
      }
    >
      <SurveyQuestion question={question} />
      <HorizontalContainer
        width={isMobile ? "auto" : "60vw"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <div style={newStyle}>
          {question.answers.map((answer, index) => {
            const onSelect = () => {
              next(answer.title);
            };
            return (
              <SelectableIcon
                key={index}
                description={answer.description}
                title={answer.title}
                isSelected={false}
                select={onSelect}
                altSrc={answer.photoAlt}
                src={answer.photo}
                fontFamily={question.fontFamily}
                fontStyling={question.fontStyling}
              />
            );
          })}
        </div>
      </HorizontalContainer>
      <SurveyFooter back={back} skipOption={skipOption} canProceed={true} />
    </div>
  );
}
