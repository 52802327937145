import React from "react";
import styled from "styled-components";
import { isBrowser, isMobile } from "react-device-detect";
// Comnponents
import { SurveyProgress } from "./Header";
// Constants
import * as strings from "../../constants/Strings";
import answerNames from "../../constants/AnswerNames";
// Themes
import "./MoleculeStyles.scss";

const AlreadyAMember = styled.h2`
  display: flex;
  height: 20px;
  cursor: default;
  color: rgb(61, 69, 67, 0.6);
  line-height: 17px;
  margin: 4% 0 0 0;
  width: auto;

  .alreadyMemberContainer:hover & {
    color: rgb(61, 69, 67, 0.8);
  }
  &:hover {
    color: rgb(61, 69, 67, 0.8);
  }

  &:active {
    color: rgb(61, 69, 67, 1);
  }
`;

export function SurveyQuestion(props) {
  const { question, toggleShowModal, signOut, isSignedIn } = props;
  const { heading, detail, type, isLogin, hideProgress } = question;

  return (
    <div
      className={
        isMobile
          ? "question_container_vertical_mobile"
          : "question_container_vertical"
      }
      style={isMobile ? { marginBottom: "0px", alignSelf: "center" } : null}
    >
      {hideProgress || isMobile ? null : (
        <SurveyProgress type={type} name={question.name} />
      )}
      <div
        className="question_text_container"
        style={
          isMobile
            ? {
                marginTop: "0px",
                marginBottom: "0px",
                width: "90vw",
                alignSelf: "center",
                alignItems: "center"
              }
            : {
                marginBottom: "10vh",
                width: "35vw"
              }
        }
      >
        <h1
          style={
            isMobile
              ? { textAlign: "center", fontSize: "25px", lineHeight: "25px" }
              : null
          }
        >
          {heading}
        </h1>
        {detail ? (
          <h2
            style={
              isMobile
                ? {
                    textAlign: "center",
                    fontSize: "15px",
                    lineHeight: "15px"
                  }
                : null
            }
          >
            {detail}
          </h2>
        ) : null}
        {isLogin ? (
          <div
            className="alreadyMemberContainer"
            onClick={isSignedIn ? signOut : toggleShowModal}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <AlreadyAMember
              onClick={isSignedIn ? signOut : toggleShowModal}
              style={
                isMobile
                  ? {
                      marginTop: "0px",
                      textAlign: "center",
                      alignItems: "center",
                      alignSelf: "center",
                      fontSize: "15px",
                      marginBottom: "10px",
                      lineHeight: "20px"
                    }
                  : { marginTop: "20%" }
              }
            >
              {isSignedIn ? "Log out" : strings.ALREADY_MEMBER}
            </AlreadyAMember>
          </div>
        ) : null}
        {isSignedIn ? null : (
          <AlreadyAMember
            onClick={isSignedIn ? signOut : toggleShowModal}
            style={
              isMobile
                ? {
                    textAlign: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    fontSize: "15px",
                    marginBottom: "10px",
                    marginTop: "0px",
                    lineHeight: "20px",
                    textDecoration: "underline"
                  }
                : {
                    textDecoration: "underline"
                  }
            }
          >
            {isLogin ? "Log in." : null}
          </AlreadyAMember>
        )}
      </div>
    </div>
  );
}
export function SurveyMessage({ question, userName }) {
  const { heading, detail, isLogin } = question;
  const needsToUseName = question.name === answerNames.gettingToKnowYou;

  return isBrowser ? (
    <div className="message_container">
      <div
        className="question_text_container"
        style={
          isMobile
            ? {
                marginTop: "0px",
                marginBottom: "0px",
                width: "100%",
                alignItems: "center"
              }
            : {
                display: "flex",
                marginTop: "20vh",
                marginBottom: "10vh",
                width: "40vw"
              }
        }
      >
        <h1 style={isMobile ? { textAlign: "center", fontSize: "45px" } : null}>
          {heading}
          {needsToUseName ? userName : ""}
        </h1>
        {detail ? (
          <h2
            style={
              isMobile
                ? { textAlign: "center", fontSize: "30px", alignSelf: "center" }
                : null
            }
          >
            {detail}
          </h2>
        ) : null}
        {isLogin ? (
          <div>
            <div
              style={
                isMobile
                  ? {
                      textAlign: "center",
                      fontSize: "20px",
                      alignSelf: "center",
                      marginBottom: "10px"
                    }
                  : null
              }
            >
              {strings.ALREADY_MEMBER}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div
      className="message_container"
      style={
        isMobile
          ? {
              marginTop: "0px",
              marginBottom: "0px",
              width: "100%"
            }
          : {
              marginTop: "10vh",
              marginBottom: "10vh",
              width: "40vw"
            }
      }
    >
      <div
        className="question_text_container"
        style={
          isMobile
            ? {
                marginTop: detail ? "10%" : "50%",
                marginBottom: "0px",
                width: "100%"
              }
            : {
                marginTop: "10vh",
                marginBottom: "10vh",
                width: "40vw"
              }
        }
      >
        <h1 style={isMobile ? { textAlign: "center", fontSize: "45px" } : null}>
          {heading}
          {needsToUseName ? userName : ""}
        </h1>
        {detail ? (
          <h2
            style={isMobile ? { textAlign: "center", fontSize: "30px" } : null}
          >
            {detail}
          </h2>
        ) : null}
        {isLogin ? (
          <div
            style={
              isMobile
                ? {
                    textAlign: "center",
                    fontSize: "20px"
                  }
                : null
            }
          >
            {strings.ALREADY_MEMBER}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export function SurveyQuestionHorizontal({ question }) {
  const { heading, type, name, hideProgress } = question;
  return (
    <div
      className="question_container_horizontal"
      style={isMobile ? { marginTop: "0px" } : null}
    >
      {hideProgress || isMobile ? null : (
        <SurveyProgress type={type} name={name} />
      )}
      <div className="message_heading">{heading}</div>
    </div>
  );
}
