import React, { useState } from "react";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
// Comnponents
import { GridScroll } from "../molecules/Grid";
import { SurveyFooter } from "../molecules/Footer";
import { SurveyQuestion } from "../molecules/Question";
import { ScrollableColumn } from "../organisms/ScrollingSection";
// Redux
import { updateAnswer } from "../../redux/actions/User";
import { getPhotoPickerPhotos } from "../../redux/actions/Photos";
// Themes
import "./PageStyles.scss";

var fetchingPhotos = false;

function ScrollingPhotoQuestion(props) {
  const {
    attemptGetPhotoPickerPhotos,
    attemptUpdateAnswer,
    back,
    credentials,
    modal,
    next,
    photoPicker,
    question,
    skipOption,
    isSignedIn,
    signOut,
    isShowingModal,
    toggleShowModal
  } = props;
  const obj = question.answers.map(() => false);
  const [selected, setSelected] = useState(obj);
  const [selectedCategories, setSelectedCategories] = useState({
    boho: 0,
    classic: 0,
    edgy: 0,
    preppy: 0,
    trendy: 0
  });

  if (photoPicker === null && !fetchingPhotos) {
    attemptGetPhotoPickerPhotos();
    fetchingPhotos = true;
  }

  const handleNext = () => {
    const noneSelected = Object.values(selectedCategories).every(
      value => value === 0
    );
    attemptUpdateAnswer(question, selectedCategories);
    if (noneSelected) {
      next();
    } else {
      next(selectedCategories);
    }
  };

  const canProceed =
    selectedCategories.boho > 0 ||
    selectedCategories.classic > 0 ||
    selectedCategories.edgy > 0 ||
    selectedCategories.preppy > 0 ||
    selectedCategories.trendy;

  return (
    <div
      onClick={isShowingModal}
      className={
        isMobile ? "answer_container_scroll_mobile" : "answer_container_scroll"
      }
    >
      <SurveyQuestion
        isSignedIn={isSignedIn}
        question={question}
        toggleShowModal={toggleShowModal}
        credentials={credentials}
        signOut={signOut}
      />
      {modal ? modal : null}
      <GridScroll>
        <ScrollableColumn
          column={0}
          totalColumns={3}
          photoPicker={photoPicker}
          question={question}
          selected={selected}
          selectedCategories={selectedCategories}
          setSelected={setSelected}
          setSelectedCategories={setSelectedCategories}
        />
        <ScrollableColumn
          column={1}
          totalColumns={3}
          photoPicker={photoPicker}
          question={question}
          selected={selected}
          selectedCategories={selectedCategories}
          setSelected={setSelected}
          setSelectedCategories={setSelectedCategories}
        />
        <ScrollableColumn
          column={2}
          totalColumns={3}
          photoPicker={photoPicker}
          question={question}
          selected={selected}
          selectedCategories={selectedCategories}
          setSelected={setSelected}
          setSelectedCategories={setSelectedCategories}
        />
      </GridScroll>
      <SurveyFooter
        canProceed={canProceed}
        skipOption={skipOption}
        back={back}
        next={handleNext}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  styleFeel: state.user.questions,
  photoPicker: state.photos.photoPicker
});

const mapDispatchToProps = dispatch => {
  return {
    attemptUpdateAnswer: (question, answer) =>
      dispatch(updateAnswer(question, answer)),
    attemptGetPhotoPickerPhotos: () => dispatch(getPhotoPickerPhotos())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollingPhotoQuestion);
