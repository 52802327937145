import React, { useState, useRef } from "react";
import { isMobile } from "react-device-detect";
// Theme
import "./AtomStyles.scss";

export function PhotoUploader({ setImages }) {
  const inputFile = useRef(null);
  const [previews, setPreview] = useState(null);

  const handleChange = e => {
    if (e.target.files[0]) {
      setPreview(e.target.files);
      setImages(e.target.files);
    }
  };

  return (
    <div className="photo_container" onClick={() => inputFile.current.click()}>
      <div
        style={
          previews ? null : { marginTop: "-5vh", justifyContent: "center" }
        }
        className="upload_container"
      >
        <div
          className="base_button_web"
          style={{
            width: "auto",
            paddingLeft: "15px",
            paddingRight: "15px",
            marginBottom: "10px"
          }}
        >
          {previews ? "Change the Photo(s)" : "Upload Photo(s)"}
        </div>
        <input
          accept=".jpg,.jpeg,.png"
          className="photo_upload_text"
          type="file"
          onChange={handleChange}
          ref={inputFile}
          style={{ display: "none" }}
          multiple
        />
        {previews ? (
          <div
            style={
              isMobile
                ? {
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto",
                    width: "90vw",
                    border: "3px solid #3D4543",
                    borderRadius: "16px",
                    marginLeft: "20px",
                    marginRight: "20px"
                  }
                : {
                    display: "flex",
                    flexDirection: "row",
                    overflowY: "auto",
                    maxWidth: "50vw",
                    maxHeight: "80vh",
                    border: "3px solid #3D4543",
                    borderRadius: "16px"
                  }
            }
          >
            {[...previews].map((preview, index) => {
              const src = URL.createObjectURL(preview);
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    backgoundColor: "#3D4543"
                  }}
                >
                  <img
                    className="photo"
                    style={
                      isMobile
                        ? {
                            width: "auto",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            borderRight: "3px solid #3D4543"
                          }
                        : {
                            height: "400px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            borderRight: "3px solid #3D4543"
                          }
                    }
                    src={src}
                    alt="Uploaded Images"
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}
