export default {
  box: require("../images/Icons/box-gray.svg"),
  boxAlt: require("../images/Icons/box-white.svg"),
  bracelet: require("../images/Icons/bracelet-gray.svg"),
  braceletAlt: require("../images/Icons/bracelet-white.svg"),
  checkmark: require("../images/Icons/checkmark-gray.svg"),
  checkmarkAlt: require("../images/Icons/checkmark-white.svg"),
  checkmarkPng: require("../images/PngIcons/checkmark-gray.png"),
  earring: require("../images/Icons/earring-gray.svg"),
  earringsAlt: require("../images/Icons/earrings-white.svg"),
  giftcard: require("../images/Icons/giftcard-gray.svg"),
  giftcardAlt: require("../images/Icons/giftcard-white.svg"),
  hair: require("../images/Icons/hair accessories-gray.svg"),
  hairAlt: require("../images/Icons/hair accessories-white.svg"),
  handbag: require("../images/Icons/handbag-gray.svg"),
  handbagAlt: require("../images/Icons/handbag-white.svg"),
  invite: require("../images/Icons/invite-gray.svg"),
  inviteAlt: require("../images/Icons/invite-white.svg"),
  necklace: require("../images/Icons/necklace-gray.svg"),
  necklaceAlt: require("../images/Icons/necklace-white.svg"),
  question: require("../images/Icons/question mark-gray.svg"),
  questionAlt: require("../images/Icons/question mark-white.svg"),
  ring: require("../images/Icons/ring-gray.svg"),
  ringAlt: require("../images/Icons/ring-white.svg"),
  scarf: require("../images/Icons/scarf-gray.svg"),
  scarfAlt: require("../images/Icons/scarf-white.svg"),
  money1: require("../images/Icons/1moneysign-gray.svg"),
  money1Alt: require("../images/Icons/1moneysign-white.svg"),
  money2: require("../images/Icons/2moneysign-gray.svg"),
  money2Alt: require("../images/Icons/2moneysign-white.svg"),
  money3: require("../images/Icons/3moneysign-gray.svg"),
  money3Alt: require("../images/Icons/3moneysign-white.svg")
};
