import React, { useState } from "react";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
// Components
import Question from "./Question";
import QuestionMobile from "./Question_Mobile";
// Constants
import { eventQuestions } from "../../constants/Questions";
import { SurveyType } from "../../shared/constants/survey";
// Redux
import {
  updateAnswer,
  submitEventResponse,
  createOrGetUser
} from "../../redux/actions/Event";
import { getPhotoPickerPhotos, uploadPhotos } from "../../redux/actions/Photos";
import { RESET_REDIRECT } from "../../redux/actions/Types";
import { trackPageView } from "../../utils/Mixpanel";

function EventSurvey(props) {
  const {
    attemptCreateOrGetUser,
    eventAnswers,
    startingQuestion,
    handleQuizChange,
    redirect,
    attemptSubmitSurvey,
    attemptResetRedirect,
    isSignedIn,
    uploadedPhotos
  } = props;

  const [currentQuestion, setCurrentQuestion] = useState(startingQuestion);
  const [questions, setQuestions] = useState(eventQuestions);
  const question = questions[currentQuestion];

  const back =
    currentQuestion > 0 ? () => setCurrentQuestion(currentQuestion - 1) : null;

  const next =
    currentQuestion < questions.length - 1
      ? addToAmount => {
          trackPageView(SurveyType.EVENT, {
            Viewed_Question: question.name,
            EventSurveyAnswers: eventAnswers
          });
          setCurrentQuestion(addToAmount);
        }
      : null;

  const handleNext = selectedAnswer => {
    let nextAmount = 1;
    let followupQuestionsArr = [];
    if (question.followupQuestion) {
      followupQuestionsArr = question.followupQuestion[selectedAnswer];
    }
    if (question.skipQuestion) {
      if (selectedAnswer) {
        props.attemptUpdateAnswer(question, selectedAnswer);
      } else {
        const keysToUse = Object.keys(question.followupQuestion)
          .filter(arrName => selectedAnswer.includes(arrName))
          .map(key => question.followupQuestion[key])
          .flat(1);
        const previousQuestions = isSignedIn
          ? eventQuestions.slice(0, currentQuestion + 1)
          : eventQuestions.slice(0, currentQuestion);
        const remainingQuestions = isSignedIn
          ? eventQuestions.slice(currentQuestion + 1)
          : eventQuestions.slice(currentQuestion);
        const newQuestions = [
          ...previousQuestions,
          ...keysToUse,
          ...remainingQuestions
        ];
        setQuestions(newQuestions);
        props.attemptUpdateAnswer(question, "");
        nextAmount = previousQuestions.length - currentQuestion;
      }
    }
    if (
      question.followupQuestion &&
      question.followupQuestion[selectedAnswer]
    ) {
      if (selectedAnswer) {
        const keysToUse =
          followupQuestionsArr.length > 0
            ? Object.keys(question.followupQuestion)
                .filter(arrName => selectedAnswer.includes(arrName))
                .map(key => question.followupQuestion[key])
                .flat(1)
            : [];

        const previousQuestions = isSignedIn
          ? eventQuestions.slice(0, currentQuestion + 1)
          : eventQuestions.slice(0, currentQuestion + 1);

        const remainingQuestions = isSignedIn
          ? eventQuestions.slice(currentQuestion + 1)
          : eventQuestions.slice(currentQuestion + 1);
        const newQuestions = [
          ...previousQuestions,
          ...keysToUse,
          ...remainingQuestions
        ];
        setQuestions(newQuestions);
        props.attemptUpdateAnswer(question, selectedAnswer);
        nextAmount = previousQuestions.length - currentQuestion;
      } else {
        props.attemptUpdateAnswer(question, "");
      }
    } else {
      if (selectedAnswer) {
        props.attemptUpdateAnswer(question, selectedAnswer);
      } else {
        props.attemptUpdateAnswer(question, "");
      }
    }
    if (currentQuestion > 1 && questions[currentQuestion].name === "email") {
      let { email, name } = eventAnswers;
      if (name === null) {
        name = email.split("@")[0];
      }
      attemptCreateOrGetUser(email, name);
    }
    next(currentQuestion + nextAmount);
    nextAmount = 1;
  };

  const submit =
    currentQuestion < questions.length - 1
      ? null
      : selectedAnswer => {
          if (question.skipQuestion) {
            if (selectedAnswer) {
              props.attemptUpdateAnswer(question, selectedAnswer);
            } else {
              const newQuestions = [
                ...eventQuestions.slice(0, currentQuestion + 1),
                question.skipQuestion,
                ...eventQuestions.slice(currentQuestion + 1)
              ];
              setQuestions(newQuestions);
              props.attemptUpdateAnswer(question, "");
            }
          } else if (question.followupQuestion) {
            if (selectedAnswer) {
              const keysToUse = Object.keys(question.followupQuestion)
                .filter(arrName => selectedAnswer.includes(arrName))
                .map(key => question.followupQuestion[key])
                .flat(1);
              const newQuestions = [
                ...eventQuestions.slice(0, currentQuestion + 1),
                ...keysToUse,
                ...eventQuestions.slice(currentQuestion + 1)
              ];
              setQuestions(newQuestions);
              props.attemptUpdateAnswer(question, selectedAnswer);
            } else {
              props.attemptUpdateAnswer(question, "");
            }
          } else {
            if (selectedAnswer) {
              props.attemptUpdateAnswer(question, selectedAnswer);
            } else {
              props.attemptUpdateAnswer(question, "");
            }
          }
          attemptSubmitSurvey();
        };

  if (redirect) {
    window.location = redirect;
    attemptResetRedirect();
  }

  return isMobile ? (
    <QuestionMobile
      surveyType="Event"
      question={question}
      back={back}
      next={handleNext}
      skipOption={question.skipOption}
      submit={submit}
      uploadPhotos={props.attemptUploadPhotos}
      handleQuizChange={handleQuizChange}
      userName=""
      previousAnswers={eventAnswers}
      uploadedPhotos={uploadedPhotos}
    />
  ) : (
    <Question
      surveyType="Event"
      question={question}
      back={back}
      next={handleNext}
      skipOption={question.skipOption}
      submit={submit}
      uploadPhotos={props.attemptUploadPhotos}
      handleQuizChange={handleQuizChange}
      userName=""
      previousAnswers={eventAnswers}
      uploadedPhotos={uploadedPhotos}
    />
  );
}

const mapStateToProps = state => ({
  eventAnswers: state.event.answers,
  eventTags: state.event.tags,
  redirect: state.event.redirect,
  isSignedIn: state.event.isSignedIn,
  uploadedPhotos: state.photos.uploadedPhotos
});

const mapDispatchToProps = dispatch => {
  return {
    attemptUpdateAnswer: (question, answer) =>
      dispatch(updateAnswer(question, answer)),
    attemptGetPhotoPickerPhotos: () => dispatch(getPhotoPickerPhotos()),
    attemptUploadPhotos: fileList =>
      dispatch(uploadPhotos(SurveyType.EVENT, fileList)),
    attemptCreateOrGetUser: () => dispatch(createOrGetUser(SurveyType.EVENT)),
    attemptSubmitSurvey: () => dispatch(submitEventResponse()),
    attemptResetRedirect: () => dispatch({ type: RESET_REDIRECT })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventSurvey);
