import {
  HIDE_LOADING_PHOTOS,
  SHOW_LOADING_PHOTOS,
  UPLOAD_SUCCESS,
  UPDATE_PHOTO_PICKER
} from "./Types";
import api from "../../api";
import { SurveyType } from "../../shared/constants/survey";

// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

export function getPhotoPickerPhotos() {
  return async function (dispatch, getState) {
    const maxResults = 23;
    try {
      dispatch({ type: SHOW_LOADING_PHOTOS });

      var result = await api.photo.getStyleQuizPics(maxResults);

      if (result.error) {
        throw new Error("Failed to get style quiz pics: " + result.error);
      }

      dispatch({ type: UPDATE_PHOTO_PICKER, data: result.data });
    } catch (e) {
      console.error(e);
    } finally {
      dispatch({ type: HIDE_LOADING_PHOTOS });
    }
  };
}

export function uploadPhotos(surveyType, fileList) {
  return async function (dispatch, getState) {
    try {
      // TODO dispatch show uploading photos

      if (!fileList || !fileList.length) {
        throw new Error("Invalid filelist for photo upload");
      }

      var surveyState;
      switch (surveyType) {
        case SurveyType.USER:
          surveyState = getState().user;
          break;
        case SurveyType.GIFT:
          surveyState = getState().gift;
          break;
        case SurveyType.EVENT:
          surveyState = getState().event;
          break;
        default:
          throw new Error("Invalid surveyType", surveyType);
      }

      var { user: userId, survey: surveyId } = surveyState;

      if (!userId || !surveyId) {
        throw new Error("Missing or invalid user or survey id");
      }

      var imageFiles = [];
      for (var i = 0; i < fileList.length; i++) {
        var file = fileList[i];
        if (!file.type.startsWith("image/")) {
          continue;
        }
        imageFiles.push(file);
      }

      var results = await Promise.all(
        imageFiles.map(async (file, i) => {
          var ext = file.name.split(".").pop();
          var path = `stylistUploads/${userId}/${surveyType}/${surveyId}/${i}.${ext}`;

          var storage = firebase
            .app()
            .storage("gs://layerist-8a595.appspot.com");
          const uploadTask = storage.ref(path);

          // Upload file
          var task = uploadTask.put(file);

          // Update progress bar
          task.on(
            "state_changed",
            function progress(snapshot) {
              // nothing done with % completion yet
            },
            function error(err) {
              console.log("error: ", err);
            },
            function complete() {
              // nothing done upon completion yet
            }
          );

          return await task.then(
            ss => {
              return path;
            },
            e => {
              console.error(e);
              return null;
            }
          );
        })
      );

      dispatch({ type: UPLOAD_SUCCESS, data: results });

      // TODO dispatch files finished uploading
    } catch (e) {
      console.error(e);
      // TODO dispatch show error?
    } finally {
      // TODO dispatch hide uploading photos
    }
  };
}
