import SurveyStringsToKeys from "../constants/SurveyStringsToKeys";

const reduxToApiEvent = eventState => {
  const { answers, survey, user, uploadedPhotos } = eventState;
  const apiGift = {
    survey,
    user,
    stylistDetails: " ",
    photoUpload: uploadedPhotos ? uploadedPhotos : [],
    ...filterAnswers(answers)
  };
  return apiGift;
};

const filterAnswers = stateAnswers => {
  const filteredAnswers = {};
  Object.keys(stateAnswers).forEach(key => {
    const value = stateAnswers[key];
    if (value !== null && value !== "") {
      if (typeof value === "object") {
        switch (key) {
          case "styleFeel":
            break;
          case "jewelryType":
            filteredAnswers["jewelryType"] = Object.values(value).map(
              val => SurveyStringsToKeys[val]
            );
            break;
          case "layerItOn":
            filteredAnswers[key] = Object.values(value).map(
              val => SurveyStringsToKeys[val]
            );
            break;
          case "stylePhotos":
            filteredAnswers[key] = value;
            break;
          default:
            Object.values(value).forEach(val => {
              filteredAnswers[key] = SurveyStringsToKeys[val];
            });
            break;
        }
      } else {
        switch (key) {
          case "otherEvent":
            filteredAnswers["otherEvent"] = value;
            break;
          case "anythingElse":
            filteredAnswers["stylistDetails"] = value;
            break;
          case "name":
            break;
          case "email":
            break;
          case "styleFeel":
            break;
          case "eventDate":
            filteredAnswers["eventDate"] = value;
            break;
          case "eventType":
            filteredAnswers["eventType"] = SurveyStringsToKeys[value];
            break;
          case "budget":
            filteredAnswers["budget"] = SurveyStringsToKeys[value];
            break;
          default:
            filteredAnswers[key] = SurveyStringsToKeys[value]
              ? SurveyStringsToKeys[value]
              : value;
            break;
        }
      }
    }
  });
  return filteredAnswers;
};

export default reduxToApiEvent;
