import {
  LOGOUT,
  SET_GIFT_ANSWERS,
  SUBMIT_GIFT_SURVEY,
  CREATE_OR_GET_GIFT,
  SUBMIT_E_CARD,
  RESET_REDIRECT
} from "../actions/Types";
import Immutable from "seamless-immutable";

import SurveyType from "../../shared/constants/survey";
import { setOrGetUserAnalytics } from "../../utils/Mixpanel";

// initial state

const initialState = Immutable({
  survey: null,
  user: null,
  surveyTpe: SurveyType.GIFT,
  credentials: {
    user: null,
    name: null,
    firstName: null,
    lastName: null,
    email: null,
    password: null
  },
  isSignedIn: false,
  answers: {
    letsGetGifting: null,
    giftRecipient: null,
    name: null,
    email: null,
    sendECard: null,
    giftName: null,
    giftEmail: null,
    requestedDeliveryDate: null,
    emailOrPrint: null,
    perfectGift: null,
    giftDeliveryDate: null,
    giftStyleFeel: null,
    giftJewelryType: null,
    budget: null,
    giftDetails: null,
    giftOrGiftee: null
  },
  tags: {},
  isLoading: false,
  redirect: null
});

// reducers
const setGiftAnswers = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    answers: {
      ...state.answers,
      [action.question.name]: action.answer
    }
  });
};

const submitGiftSurvey = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    redirect: action.redirect
  });
};

const resetRedirect = (state, action) => {
  return Immutable.merge(state, {
    ...state,
    redirect: null
  });
};

const setRedirect = (state, action) => {
  const redirect = action.question.redirect(action.answer);
  return Immutable.merge(state, {
    ...state,
    redirect
  });
};

const createOrGetUser = (state, action) => {
  const { name, user, survey, email } = action;
  setOrGetUserAnalytics({ name, user, survey, email });

  return Immutable.merge(state, {
    ...state,
    user: user ? user : state.user,
    survey: survey ? survey : state.survey,
    credentials: {
      ...state.credentials,
      user: user ? user : state.credentials.user,
      name: name ? name : state.credentials.name,
      email: email ? email : state.credentials.email,
      firstName: name ? name : state.credentials.name
    },
    answers: {
      ...state.answers,
      name: name ? name : state.answers.name,
      email: email ? email : state.answers.email
    },
    isSignedIn: true,
    isLoading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_OR_GET_GIFT:
      return createOrGetUser(state, action);
    case SET_GIFT_ANSWERS: {
      if (action.question.redirect && action.answer === "E-Card") {
        return setRedirect(state, action);
      } else {
        return setGiftAnswers(state, action);
      }
    }
    case SUBMIT_E_CARD:
      return submitGiftSurvey(state, action);
    case SUBMIT_GIFT_SURVEY:
      return submitGiftSurvey(state, action);
    case RESET_REDIRECT:
      return resetRedirect(state, action);
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
