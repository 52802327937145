/* ERRORS */
export const GENERIC_ERROR = "An error has occurred. Please try again later.";
export const TWITTER_CANCEL = "User cancelled login flow.";
export const EXISTS_ON_OTHER =
  "This email associated with this social media account has already been used for a different sign in method.";
export const NETWORK_ERROR =
  "Couldn't connect to Layerist. Please check your internet connection then try again.";
export const USER_NOT_FOUND = "No user with that email was found.";
export const MISSING_TOKEN =
  "An authorization error has occurred. Please sign out then back in.";
export const LOCATION_UNSUPPORTED =
  "Your zip code is not supported at this time. Would you like to be notified when your location is supported?";
export const SUBSCRIPTION_UNAVAILABLE =
  "Another user is already subscribed to this zip code. Please change your primary zip code or unselect Non-Profit.";
export const NO_NON_PROFIT =
  "A Non-Profit has not yet claimed this zip code. Sign up in the selected zip code will be unavailable until a Non-Profit has been approved.";
export const EMAIL_ALREADY_USED =
  "That email address is already in use. Please sign in or choose another email.";
export const INCORRECT_EMAIL_PASSWORD =
  "Incorrect email / password combination.";
export const INCORRECT_PASSWORD = "That password is incorrect.";
export const INVALID_EIN = "Must use a valid EIN.";
export const UNDEFINED = "An undefined error occured";

/* HEADER */
export const STYLE = "STYLE";
export const LAYERS = "LAYERS";
export const DETAILS = "DETAILS";

/* SURVEY MISC */
export const ALREADY_MEMBER = "Already a member?";
