import axios from "axios";

export class ApiResult {
  constructor(data, success, error, code) {
    this.data = data;
    this.success = success;
    this.error = error;
    this.code = code;
  }
}

export class ApiError extends Error {
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

export class BaseApi {
  getApiRequest(url, params) {
    return this.sendApiRequest(url, "GET", null, params);
  }

  postApiRequest(url, data) {
    return this.sendApiRequest(url, "POST", data);
  }

  putApiRequest(url, data) {
    return this.sendApiRequest(url, "PUT", data);
  }

  deleteApiRequest(url, data) {
    return this.sendApiRequest(url, "DELETE", data);
  }

  async sendApiRequest(url, method, data, params) {
    const start = Date.now();

    var result;
    var status;

    try {
      if (typeof url !== "string" || typeof method !== "string") {
        throw new Error(`Invalid params passed to callFunction: '${method}'`);
      }

      var requestData = { url, method, data, params };
      // if (file) requestData.responseType = "blob";

      const response = await axios.request(requestData);
      status = response.status;
      if (response.error) {
        const { message, code } = response.error;
        throw new ApiError(message, code);
      }

      result = new ApiResult(response.data, true);
    } catch (e) {
      // an axios error
      if (e.response) {
        console.warn("Caught axios error:", e.response.data);
        status = e.response.status;
        const error = e.response.data || {};
        // token needs refreshing, and haven't already tried refreshing

        result = new ApiResult(e.response.data, false, error.error, error.code);
      } else {
        console.warn("Caught error:", e.message);
      }
    }

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      const elapsed = Date.now() - start;
      console.log(
        `[API] finished apiRequest ${method} '${url}' in`,
        elapsed,
        "MS with status",
        status,
        "Sent the following: ",
        data,
        "; result data: ",
        JSON.parse(JSON.stringify(result.data || {})) // make a copy so the console shows the snapshot, not the latest
      );
    }
    return result;
  }
}
