export default {
  addEventBox: "addEventBox",
  anythingElse: "anythingElse",
  beadVsBangle: "beadVsBangle",
  bigEvent: "bigEvent",
  chokerVsBib: "chokerVsBib",
  claspVsCuff: "claspVsCuff",
  colorfulVsNeutral: "colorfulVsNeutral",
  delicateVsStatement: "delicateVsStatement",
  delicateVsStatementRing: "delicateVsStatementRing",
  email: "email",
  emailOrPrint: "emailOrPrint",
  eventType: "eventType",
  eventDate: "eventDate",
  frequency: "frequency",
  gettingToKnowYou: "gettingToKnowYou",
  giftDeliveryDate: "giftDeliveryDate",
  giftDetails: "giftDetails",
  giftEmail: "giftEmail",
  giftJewelryType: "giftJewelryType",
  giftName: "giftName",
  giftOrGiftee: "giftOrGiftee",
  giftRecipient: "giftRecipient",
  giftStyleFeel: "giftStyleFeel",
  goldVsSilver: "goldVsSilver",
  hoopVsStatement: "hoopVsStatement",
  instagram: "instagram",
  jewelryType: "jewelryType",
  layeredVsStrand: "layeredVsStrand",
  layerItOn: "layerItOn",
  letsGetGifting: "letsGetGifting",
  giftSubmit: "giftSubmit",
  name: "name",
  giftWhen: "giftWhen",
  ohAParty: "ohAParty",
  otherEvent: "otherEvent",
  perfectGift: "perfectGift",
  photoUpload: "photoUpload",
  pinterest: "pinterest",
  requestedDeliveryDate: "requestedDeliveryDate",
  ringSize: "ringSize",
  sendECard: "sendECard",
  shortVsLong: "shortVsLong",
  studVsDangle: "studVsDangle",
  styleFeel: "styleFeel",
  stylePhotos: "stylePhotos",
  stylingVideo: "stylingVideo",
  stylistDetails: "stylistDetails",
  budget: "budget",
  weddingDetails: "weddingDetails"
};
