import { BaseApi } from "./Base";

class UserApi extends BaseApi {
  createOrGetUser(email, name, type) {
    return super.postApiRequest(`/user`, { email, name, type });
  }
  login(email, password) {
    return super.postApiRequest(`/user/login`, { email, password });
  }
  signUp({ firstName, lastName, email, password }) {
    return super.postApiRequest(`/user/signUp`, {
      firstName,
      lastName,
      email,
      password
    });
  }
}

const userApi = new UserApi();

export default userApi;
