import React, { useState } from "react";
import styled from "styled-components";
// Components
import { isMobile } from "react-device-detect";
import { SvgImage, PngImage } from "./Images";
// Constants
import Images from "../../constants/Images";
// Theme
import "./AtomStyles.scss";

const mobileWidth = "120px";

const StyledIconContainer = styled.div`
  height: ${() => (isMobile ? mobileWidth : "150px")};
  width: ${() => (isMobile ? mobileWidth : "150px")};
  display: flex;
  cursor: default;
  align-items: center;
  align-content: center;
  text-align: center;
  background-color: ${props => (props.isSelected ? "#336472" : "#FDE6E1")};
  border-radius: ${props => (props.rounded ? "16px" : "0px")};
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 10px;

  &:hover {
    background-color: ${() =>
      isMobile ? "#FDE6E1" : "rgba(51, 100, 114, 0.8)"};
    color: ${() => (isMobile ? null : "white")};
    box-shadow: ${() =>
      isMobile ? null : "2px 5px 10px rgba(28, 34, 51, 0.2)"};
  }
`;

const StyledImageContainer = styled.div`
  height: ${() => (isMobile ? mobileWidth : "150px")};
  width: ${() => (isMobile ? mobileWidth : "150px")};
  cursor: default;
  align-items: center;
  background-color: ${props => (props.isSelected ? "#336472" : "#FDE6E1")};
  border-radius: ${props => (props.rounded ? "16px" : "0px")};
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 10px;

  &:hover {
    background-color: ${props =>
      isMobile
        ? null
        : props.isSelected
        ? "rgba(51, 100, 114, 0.2)"
        : "rgba(51, 100, 114, 0.8)"};
    color: ${() => (isMobile ? "#3D4543" : "white")};
    box-shadow: ${() =>
      isMobile ? null : "2px 5px 10px rgba(51, 100, 114, 0.2)"};
  }
`;

const StyledText = styled.div`
  color: ${props => (props.isSelected ? "white" : "#3D4543")};
  align-self: center;
  align-items: center;
  text-align: center;
  font-family: "Sofia Pro Regular";
  font-size: ${() => (isMobile ? "16px" : "16px")};

  ${StyledImageContainer}:hover & {
    color: ${() => (isMobile ? null : "white")};
  }
  ${StyledIconContainer}:hover & {
    color: ${() => (isMobile ? null : "white")};
  }
`;

const StyledTitle = styled.h1`
  color: ${props => (props.isSelected ? "white" : "#3D4543")};
  font-size: ${() => (isMobile ? "30px" : "24px")};
  margin: 10px 0 0 0;
  padding: 10px 10px 10px 10px;
  font-family: "Sofia Pro Regular";

  ${StyledImageContainer}:hover & {
    color: ${() => (isMobile ? null : "white")};
  }
  ${StyledIconContainer}:hover & {
    color: ${() => (isMobile ? null : "white")};
  }
`;
const StyledDescription = styled.h2`
  color: ${props => (props.isSelected ? "white" : "#3D4543")};
  font-size: ${() => (isMobile ? "18px" : "18px")};
  text-align: center;
  align-self: center;
  margin-top: 0px;
  font-family: "Sofia Pro Regular";

  ${StyledImageContainer}:hover & {
    color: ${() => (isMobile ? null : "white")};
  }
  ${StyledIconContainer}:hover & {
    color: ${() => (isMobile ? null : "white")};
  }
`;

export function SelectableIcon({
  altSrc,
  src,
  select,
  description,
  isSelected,
  title,
  fontStyling,
  fontFamily
}) {
  const [useAlt, setUseAlt] = useState(false);
  let customFontStyle = {
    fontFamily: "Sofia Pro Regular",
    fontSize: "30px",
    ...fontStyling
  };
  if (fontFamily) {
    customFontStyle = {
      ...customFontStyle,
      fontFamily: fontFamily
    };
  }

  return src ? (
    <StyledImageContainer
      onClick={select}
      isSelected={isSelected}
      rounded
      onMouseEnter={isMobile ? null : () => setUseAlt(true)}
      onMouseLeave={isMobile ? null : () => setUseAlt(false)}
    >
      <SvgImage size={isMobile ? 40 : 66} src={useAlt ? altSrc : src} rounded />
      <StyledText isSelected={isSelected}>{title}</StyledText>
    </StyledImageContainer>
  ) : (
    <StyledIconContainer onClick={select} isSelected={isSelected} rounded>
      <StyledTitle isSelected={isSelected} style={customFontStyle}>
        {title}
      </StyledTitle>
      {description ? (
        <StyledDescription isSelected={isSelected}>
          {description}
        </StyledDescription>
      ) : null}
    </StyledIconContainer>
  );
}

export function GridPhoto({ src, select, isSelected }) {
  const mobileWidth = "150px";
  const containerStlye = {
    lineHeight: 0,
    position: "relative",
    borderWidth: "0px",
    borderColor: "white",
    zIndex: 100,
    borderRadius: "16px",
    marginBottom: "10px",
    backgroundColor: isSelected ? "#336472" : "white"
  };

  return (
    <div onClick={select} style={containerStlye}>
      {isSelected ? (
        <PngImage absolute size={21} src={Images.checkmarkPng} />
      ) : null}
      <img
        alt="simple_photo"
        className={isSelected ? "simple_photo_selected" : "simple_photo"}
        src={src}
        style={isMobile ? { width: mobileWidth, height: mobileWidth } : null}
      />
    </div>
  );
}

export function GridPhotoThisThat({ src, select, isSelected }) {
  const altString = "";
  return (
    <img
      alt={altString}
      onClick={select}
      className={isSelected ? "this_that_photo_selected" : "this_that_photo"}
      src={src}
      style={
        isMobile
          ? { maxWidth: "40vw", width: "150px", height: "180px" }
          : { maxWidth: "20vw" }
      }
    />
  );
}

export function ScrollPhoto({ src, select, isSelected }) {
  const [aspectRatio, setAspectRatio] = useState(0);
  const [showTemp, toggleTemp] = useState(true);
  const onImgLoad = ({ target: img }) => {
    const ratio = img.naturalWidth / img.naturalHeight;
    toggleTemp(false);
    setAspectRatio(ratio);
  };
  const photoStyle = {
    height: aspectRatio === 1 ? "250px" : aspectRatio > 1 ? "200px" : "400px",
    width: isMobile ? "250px" : "250px",
    borderRadius: "16px",
    opacity: isSelected ? 0.4 : 1
  };

  const containerStlye = {
    backgroundColor: showTemp ? "#dedede" : "#336472",
    borderColor: "white",
    borderRadius: "16px",
    borderWidth: "0px",
    height: aspectRatio === 1 ? "250px" : aspectRatio > 1 ? "200px" : "400px",
    margin: "5px",
    position: "sticky",
    width: isMobile ? "200px" : "250px",
    zIndex: 100
  };

  const altString = "";
  return (
    <div
      onClick={select}
      className={showTemp ? "icon" : ""}
      style={containerStlye}
    >
      {isSelected ? (
        <PngImage absolute size={21} src={Images.checkmarkPng} />
      ) : null}
      <img alt={altString} onLoad={onImgLoad} style={photoStyle} src={src} />
    </div>
  );
}

export function GridText() {
  return <div className="simple_photo">Example</div>;
}
