import React, { useState } from "react";

import { isMobile } from "react-device-detect";
// Themes
import "./MoleculeStyles.scss";
import BackButton from "../../images/PngIcons/backButton.png";
import { PngImage } from "../atoms/Images";

export function SurveyFooter(props) {
  const { back, next, skipOption, canProceed } = props;
  const handleSkip = () => {
    next();
  };
  const submit = props.submit ? props.submit : null;
  const showRight = next || skipOption;
  return (
    <div
      className={isMobile ? "footer_container_mobile" : "footer_container"}
      style={showRight ? null : { width: "15vw" }}
    >
      <div className="footer_left_container">
        <Back back={back} />
      </div>
      <div className="footer_right_container">
        <Skip next={handleSkip} skipOption={skipOption} />
        <Next next={next} submit={submit} canProceed={canProceed} />
        <Submit submit={submit} />
      </div>
    </div>
  );
}
export function ThisThatFooter({ next, neitherDisabled }) {
  const handleBoth = () => {
    next("both");
  };
  const handleNeither = () => {
    next("neither");
  };
  return (
    <div
      className="this_that_options"
      style={
        isMobile
          ? { minHeight: "100px", marginTop: "25px" }
          : { minHeight: "100px" }
      }
    >
      <Both next={handleBoth} />
      {neitherDisabled ? null : <Neither next={handleNeither} />}
    </div>
  );
}
export function TextFooter(props) {
  const { back, canProceed, next, handleSkip, skipOption } = props;
  const submit = props.submit ? props.submit : null;
  return (
    <div className="footer_container">
      <Back back={back} />
      <div className="footer_right_container">
        <Skip next={handleSkip} skipOption={skipOption} />
        <Next next={next} submit={submit} canProceed={canProceed} />
        <Submit submit={submit} />
      </div>
    </div>
  );
}

function Back({ back }) {
  return back ? (
    <div className="back_button_arrow_container" onClick={back}>
      <PngImage src={BackButton} size="40px" rounded />
    </div>
  ) : null;
}

export function Skip({ next, skipOption }) {
  return next && skipOption ? (
    <div className="footer_skip" onClick={next}>
      {skipOption}
    </div>
  ) : null;
}

export function Next({ canProceed, next, submit }) {
  return next && submit === null ? (
    <div
      className={canProceed ? "base_button_web" : "disabled_button_web"}
      onClick={canProceed ? next : null}
    >
      Next
    </div>
  ) : null;
}

export function Both({ next }) {
  return next ? (
    <div
      className={isMobile ? "base_button_mobile" : "base_button_web"}
      style={
        isMobile
          ? { marginLeft: "0px" }
          : { marginTop: "10px", marginLeft: "0px" }
      }
      onClick={next}
    >
      Both
    </div>
  ) : null;
}

export function Neither({ next }) {
  return next ? (
    <div
      className={isMobile ? "base_button_mobile" : "base_button_web"}
      style={{ marginLeft: "0px" }}
      onClick={next}
    >
      Neither
    </div>
  ) : null;
}

export function Submit({ submit }) {
  const [isLoading, setLoading] = useState(false);
  const handleSubmit = evt => {
    evt.preventDefault();
    setLoading(true);
    if (!isLoading) {
      submit();
    }
  };
  return submit ? (
    <div
      className={isMobile ? "base_button_mobile" : "base_button_web"}
      onClick={isLoading ? null : handleSubmit}
      style={
        isLoading
          ? {
              backgroundColor: "rgb(222, 222, 222)",
              color: "white"
            }
          : null
      }
    >
      {isLoading ? "Loading" : "Continue"}
    </div>
  ) : null;
}

export function CloseButton({ closeModal }) {
  const handleClose = () => {
    closeModal();
  };
  const extraMargin = {
    marginRight: "30px"
  };
  return (
    <div
      className={isMobile ? "base_button_mobile" : "base_button_web"}
      style={extraMargin}
      onClick={handleClose}
    >
      Continue
    </div>
  );
}
