import SurveyStringsToKeys from "../constants/SurveyStringsToKeys";

const reduxToApiGift = giftState => {
  const { user, survey, answers } = giftState;
  const apiGift = {
    user: user,
    survey: survey,
    ...filterAnswers(answers)
  };
  return apiGift;
};

const filterAnswers = stateAnswers => {
  const filteredAnswers = {};
  // todo refactor to for each
  Object.keys(stateAnswers).forEach(key => {
    const value = stateAnswers[key];
    if (
      key !== "sendECard" &&
      key !== "giftSubmit" &&
      value !== null &&
      value !== ""
    ) {
      if (typeof value === "object") {
        switch (key) {
          case "giftStyleFeel":
            filteredAnswers["styleFeel"] = Object.values(value).map(
              val => SurveyStringsToKeys[val]
            );
            break;
          case "giftJewelryType":
            filteredAnswers["jewelryType"] = Object.values(value).map(
              val => SurveyStringsToKeys[val]
            );
            break;
          case "layerItOn":
            filteredAnswers[key] = Object.values(value).map(
              val => SurveyStringsToKeys[val]
            );
            break;
          case "stylePhotos":
            filteredAnswers[key] = value;
            break;
          default:
            Object.values(value).forEach(val => {
              filteredAnswers[key] = SurveyStringsToKeys[val];
            });
            break;
        }
      } else {
        switch (key) {
          case "giftStyleFeel":
            filteredAnswers["styleFeel"] = SurveyStringsToKeys[value];
            break;
          case "giftName":
            filteredAnswers["name"] = value;
            break;
          case "giftWhen":
            filteredAnswers["eventDate"] = value;
            break;
          case "budget":
            filteredAnswers["budget"] = SurveyStringsToKeys[value];
            break;
          default:
            filteredAnswers[key] = SurveyStringsToKeys[value]
              ? SurveyStringsToKeys[value]
              : value;
            break;
        }
      }
    }
  });
  if (
    stateAnswers["giftStyleFeel"] === undefined ||
    stateAnswers["giftStyleFeel"] === null
  ) {
    filteredAnswers["styleFeel"] = ["I_DONT_KNOW"];
  }
  return filteredAnswers;
};
export default reduxToApiGift;
